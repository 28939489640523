import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import Cookies from "js-cookie";
import NavBar from "../../../Layouts/NavBar";
import FilterListSchool from "./FilterListsSchool";
import { useAuth } from "../../../Services/AuthContext";

export default function FilterByAge() {
  const [Minage, setMinAge] = useState([]);
  const [Maxage, setMaxAge] = useState([]);
  const [school, setSchool] = useState([]);

  const auth = useAuth()
  const ref = useRef()
  const showData = async(e) => {
    e.preventDefault()

    const response = await axios.post(`/api/school/addByAge`,{
      school: school, MinAge: Minage, MaxAge: Maxage
    },{
      headers: {
        authorization: `Bearer ${auth.Ldata}`
      }, withCredentials: true
    })
    console.log(response)
  };
  return (
    <>
      <header>
        <NavBar />
        <FilterListSchool />
      </header>
      <div>
        <form className="row g-3" onSubmit={showData}>
          <div className="col-auto">
            <div className="col-auto input-field">
              <label for="inputAge" className="visually-hidden">
                Edad
              </label>
              <input
                type="text"
                className="form-control"
                id="inputAge"
                placeholder="Edad Minima"
                onChange={(e) => setMinAge(e.target.value)}
              />
            </div>
          </div>
          <div className="col-auto">
            <div className="col-auto input-field">
              <label for="inputAge" className="visually-hidden">
                Edad
              </label>
              <input
                type="text"
                className="form-control"
                id="inputAge"
                placeholder="Edad Maxima"
                onChange={(e) => setMaxAge(e.target.value)}
              />
            </div>
          </div>
          <div className="col-auto">
            <select
              className="form-select"
              aria-label="Operator"
              onChange={(e) => setSchool(e.target.value)}
            >
              <option selected>Seleccione el Escuela</option>
              <option value="1">Natacion</option>
              <option value="2">Futbol</option>
              <option value="3">Baseball</option>
              <option value="4">Atletismo</option>
            </select>
          </div>
          <div className="col-auto">
            <button type="submit" className="btn btn-primary mb-3">
              Search Data
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
