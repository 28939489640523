import React, { useState, useEffect } from "react";
import NavBar from "./Layouts/NavBar";
import FilterPrograms from "./Views/FilterViews/Programs/FiltersPrograms";

export default function Programs() {
  return (
    <>
      <header>
        <NavBar />
        <FilterPrograms />
      </header>
    </>
  );
}
