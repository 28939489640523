import { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Services/AuthContext";
import axios from "axios";
import "../../../Stylesheet/Login.css"
import Cookies from "js-cookie";

export default function Login(){
    const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const Auth = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!e.target.checkValidity()) {
        console.log("No send");
      } else {
        const response = await axios.post(
          "/api/auth/login",
          {
            email: email,
            password: password,
          },
          { withCredentials: true }
        );
        console.log(response)
        if (response.status === 200) {
          console.log(response.data);
          Auth.setLdata(response.data);
          Auth.setAuthenticate(true)
          navigate("/",{replace: true})
          Cookies.set('UAT',response.data,{expires: 1})
        }
        if (response.status === 401) {
          console.log(response)
          Auth.setAuthenticate(false)
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);
    return(
        <body>
            <div className="form my-4 mx-5">
                <div className="container container-login-data">
                    <div className="row no-gutters">
                        <div className="col-lg-5">
                            <img src={require("../../../images/Logo.png")} className="img-fluid" alt=""/>
                        </div>
                        <div className="col-lg-7 px-5 pt-5">
                            <h1 className="front-wight-bold py-3">Iniciar Sesion</h1>
                            <form className="needs-validation" onSubmit={handleSubmit}>
                                <div className="form-row was-validated">
                                    <div className="col-lg-7">
                                        <input type="email" placeholder="Correo Electronico" className="form-control my-2 p-3" 
                                        onChange={(e) =>{setEmail(e.target.value)}} required/>
                                        <div className="invalid-feedback">Please Enter Your Email</div>
                                    </div>
                                </div>
                                <div className="form-row was-validated">
                                    <div className="col-lg-7">
                                        <input type="password" placeholder="Contraseña" className="form-control my-2 p-3"
                                        onChange={(e)=>{setPassword(e.target.value)}} required/>
                                    </div>
                                    <div className="invalid-feedback">Please Enter your Password</div>
                                </div>
                                <div className="form-row">
                                    <div className="col-lg-7">
                                        <button type="submit" className="btn1 mt-3 mb-5">Ingresar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div> 
                </div>
            </div>
        </body>
    )
}