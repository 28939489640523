import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import NavBar from "../../../Layouts/NavBar";
import FilterMembers from "./FilterMembers";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuth } from "../../../Services/AuthContext";

export default function MemberSpecies() {
  const [employee, setEmployee] = useState([]);
  const [species, setSpecies] = useState([]);

  const auth = useAuth()
  const ref = useRef();

  const handlePrint = useReactToPrint({
    content: () => ref.current,
    documentTitle: "Reporte Por edad",
  });

  const showData = async (e) => {
    e.preventDefault();

    const response = await toast.promise(
      axios.post(`/api/employee/species`,{
        species: species
      },{
        headers: {
          authorization: `Bearer ${auth.Ldata}`
        },
        withCredentials: true
      }),{
        pending: 'Filtrando...',
        success: 'Filtrado Exitoso',
        error: 'Error'
      }
    )
    console.log(response)
    setEmployee(response.data)
  };

  useEffect(() => {
    showData();
  }, []);
  return (
    <>
      <header>
        <NavBar />
        <FilterMembers />
      </header>
      <div>
        <div className="container-fluid">
          <form className="row g-3" onSubmit={showData}>
            <div className="col-auto">
              <select
                className="form-select"
                aria-label="gender"
                onChange={(e) => setSpecies(e.target.value)}
              >
                <option selected>Genero</option>
                <option value="1">Jaiba</option>
                <option value="2">Langosta</option>
              </select>
            </div>
            <div className="col-auto">
              <button type="submit" className="btn btn-primary mb-3">
                Search Data
              </button>
            </div>
          </form>
        </div>
        <div className="data-container justify-content-center align-items-center">
          <spam>Total Filtrado: {employee?.length > 0 ? employee?.length : 0}</spam>
          <button onClick={handlePrint} className="btn btn-primary mb-3">
            Descargar Reporte
          </button>
        </div>
        <div className="table-responsive" ref={ref}>
        <table className="table table-striped table-hover mt-5 shadow-lg">
          <thead>
            <tr className="bg-grupo text-black ">
              <th>id</th>
              <th>Primer Nombre</th>
              <th>Segundo Nombre</th>
              <th>Primer Apellido</th>
              <th>Segundo Apellido</th>
            </tr>
          </thead>
          <tbody>
            {Object.values(employee).map((employees) => (
              <tr key={employees.cc}>
                <td>{employees.cc}</td>
                <td>{employees.firstname}</td>
                <td>{employees.secondname}</td>
                <td>{employees.first_lastname}</td>
                <td>{employees.second_lastname}</td>
                <td>
                  <Link
                    to={`/Miembros/species/${employees.cc}`}
                    className="btn btn-outline-primary text-center"
                  >
                    Mas Informacion
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
    </>
  );
}
