import axios from "axios";
import { useState, useEffect } from "react";
import NavBar from "../NavBar";
import { useParams } from "react-router-dom";
import { useAuth } from "../../Services/AuthContext";

export default function UpdateFilesEmployees() {
  const [CC_file, setCC_file] = useState([]);
  const [typeCC_file, setTypeCC_file] = useState("");
  const [fishing_certificate, setFishingCertificate] = useState([]);
  const [typeFishing_certificate, setTypeFishing_Certificate] = useState("");
  const [familyPicture, setFamilyPicture] = useState([]);
  const [typeFamilyPicture, setTypeFamilyPicture] = useState("");

    const {id} = useParams()
    const auth = useAuth()
  const convertToBase64 = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setCC_file(reader.result.toString().substr(23));
      setTypeCC_file(file.type);
    };
    reader.readAsDataURL(file);
  };

  const SetCertificaded = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setFishingCertificate(reader.result.toString().substr(23));
      setTypeFishing_Certificate(file.type);
    };
    reader.readAsDataURL(file);
  };

  const SetFamilyPhoto = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setFamilyPicture(reader.result.toString().substr(23));
      setTypeFamilyPicture(file.type);
    };
    reader.readAsDataURL(file);
  };

  const showDatabase = async (e) => {
    e.preventDefault()
    await axios.put(`/api/employee/setFiles/${id}`, {
      "CC_file": CC_file ? CC_file : null,
      "typeCC_file": typeCC_file ? typeCC_file : null,
      "familyPicture": familyPicture ? familyPicture : null,
      "typePicture": typeFamilyPicture ? typeFamilyPicture : null,
      "Certificate_Fishing" : fishing_certificate ? fishing_certificate : null,
      "typeFishing_certificate" : typeFishing_certificate ? typeFishing_certificate : null,
    },{
        headers:{
            authorization: `Bearer ${auth.Ldata}`
        },withCredentials: true
    }).then(response =>{ console.log(response)});
    
  };

  return (
    <>
      <header>
        <NavBar />
      </header>
      <body>
        <div className="container">
          <form
            className="row mt-3 g-3 needs-validation"
            onSubmit={showDatabase}
            noValidate
          >
            <div className="col-4">
              <label for="inputFile" className="form-label">
                Cedula de Ciudadania
              </label>
              <input
                type="file"
                className="form-control"
                onChange={(e) => convertToBase64(e)}
              />
            </div>
            <div className="col-4">
              <label for="inputCertificate" className="form-label">
                Certificado de Pesca
              </label>
              <input
                type="file"
                className="form-control"
                onChange={(e) => SetCertificaded(e)}
              />
            </div>
            <div className="col-4">
              <label for="inputPhoto" className="form-label">
                Foto Familiar
              </label>
              <input
                type="file"
                className="form-control"
                onChange={(e) => SetFamilyPhoto(e)}
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Enviar
            </button>
          </form>
        </div>
      </body>
    </>
  );
}
