import React, { useEffect, useState } from "react";
import NavBar from "../../components/Layouts/NavBar";
import { useAuth } from "../Services/AuthContext";
import axios from "axios";
import DoughNut from "../../Charts/DoughNut";
import "../../Stylesheet/Home.css";
import BarChart from "../../Charts/Bar";
import ProgramsBar from "../../Charts/ProgramsBar";
import SchoolsBar from "../../Charts/SchoolsBar";

export default function Home() {
  const [CantAll, setCantAll] = useState(0);
  const [CantCienaga, setCantCienaga] = useState(0);
  const [CantBuenaventura, setCantBuenaventura] = useState(0);
  const [cantKinder, SetcantKinder] = useState(0);
  const [CantPreescolar, SetCantPreescolar] = useState(0);
  const [CantPrimaria, SetCantPrimaria] = useState(0);
  const [CantSecundaria, SetCantSecundaria] = useState(0);
  const [CantTecnica, setCantTecnica] = useState(0);
  const [CantProfesional, setCantProfesional] = useState(0);
  const [CantOtro, SetCantOtro] = useState(0);
  const [Masculino, SetMasculino] = useState(0);
  const [Femenino, SetFemenino] = useState(0);

  const [CantMember, setCantMember] = useState(0);

  const Auth = useAuth();

  const searchData = async () => {
    const [response1, response2] = await Promise.all([
      axios.get(`/api/principal/allsons`, {
        headers: {
          Authorization: `Bearer ${Auth.Ldata}`,
        },
      }),
      axios.get(`/api/principal/memberData`, {
        headers: {
          Authorization: `Bearer ${Auth.Ldata}`,
        },
      }),
    ]);
    const response = await axios.get(`/api/principal/allsons`, {
      headers: {
        Authorization: `Bearer ${Auth.Ldata}`,
      },
    });
    console.log(response.data);
    setCantAll(response1.data.cant);
    setCantCienaga(response1.data.cantCienaga);
    setCantBuenaventura(response1.data.cantBuenaventura);
    SetcantKinder(response1.data.cantKinder);
    SetCantPreescolar(response1.data.CantPreescolar);
    SetCantPrimaria(response1.data.CantPrimaria);
    SetCantSecundaria(response1.data.CantSecundaria);
    setCantTecnica(response1.data.CantTecnica);
    setCantProfesional(response1.data.CantProfesional);
    SetCantOtro(response1.data.CantOtro);
    SetMasculino(response1.data.Masculino);
    SetFemenino(response1.data.Femenino);
    setCantMember(response2.data.CantMember);
  };

  useEffect(() => {
    searchData();
  }, []);
  return (
    <>
      <header>
        <NavBar />
      </header>
      <body>
        <div className="container-fluid">
          <div className="d-sm-flex align-items-center justify-content-start mb-4">
            <h1 className="h3 mb-0 font-tittle">Informacion General</h1>
          </div>

          <div className="row">
            <div className="col-xl-3 col-md-6 mb-4">
              <div className="card border-left-primary shadow h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold textColorMember text-uppercase mb-1">
                        Total Miembros
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        {CantMember}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-6 mb-4">
              <div className="card border-left-primary shadow h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold textColor text-uppercase mb-1">
                        Total Beneficiarios
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        {CantAll}
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-calendar fa-2x text-gray-300"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-6 mb-4">
              <div className="card border-left-primary shadow h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold textColor text-uppercase mb-1">
                        Beneficiarios - Bn/tura
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        {CantBuenaventura}
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-calendar fa-2x text-gray-300"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-md-6 mb-4">
              <div className="card border-left-primary shadow h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold textColor text-uppercase mb-1">
                        Beneficiarios - Cienaga
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        {CantCienaga}
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-calendar fa-2x text-gray-300"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-sm-flex align-items-center justify-content-start mb-4">
              <h1 className="h3 mb-0 font-tittle">Informe de Beneficiarios</h1>
            </div>

            <div className="row">
              <div className="col-xl-4 col-lg-5">
                <div className="card shadow mb-4">
                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold">
                      Registros Por Generos
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="chart-pie pt-4 pb-2">
                      <DoughNut masculino={Masculino} femenino={Femenino} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-7">
                <div className="card shadow mb-4">
                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-start">
                    <h6 className="m-0 font-weight-bold">
                      Registros Por Escolaridad
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="chart-bar">
                      <BarChart
                        kinder={cantKinder}
                        preescolar={CantPreescolar}
                        primaria={CantPrimaria}
                        secundaria={CantSecundaria}
                        tecnica={CantTecnica}
                        profesional={CantProfesional}
                        otro={CantOtro}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*
              <div className="row">
                <div className="col-lg-6 mb-4">
                  <div className="card shadow mb-4">
                    <div className="card-header py-3">
                      <h6 className="m-0 font-weight-bold">
                        Informe Por Programas
                      </h6>
                    </div>
                    <div className="card-body">
                      <div className="chart-bar">
                        <ProgramsBar
                          pescandotalentos={13}
                          olasdelfuturo={34}
                          vistelapesca={12}
                          tiburonmama={2}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mb-4">
                  <div className="card shadow mb-4">
                    <div className="card-header py-3">
                      <h6 className="m-0 font-weight-bold">
                        Informe de Escuelas
                      </h6>
                    </div>
                    <div className="card-body">
                      <div className="chart-bar">
                        <SchoolsBar
                          futbol={20}
                          baseball={12}
                          basketBall={32}
                          danza={11}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  */}
          </div>
        </div>
      </body>
    </>
  );
}
