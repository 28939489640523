import { useContext, createContext, useState } from "react";
import Cookies from "js-cookie"
const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {

  const [Ldata, setLdata] = useState("")
  const [authenticate, setAuthenticate] = useState(false)
  const [status, setStatus] = useState(0)

  const logout = () => {
  };

  return (
    <AuthContext.Provider value={{ setStatus,status,authenticate,setAuthenticate,Ldata,setLdata, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
