import React, { useState, useEffect, useRef } from "react";
import NavBar from "../../../Layouts/NavBar";
import FilterListSchool from "./FilterListsSchool";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../../Services/AuthContext";

export default function ListBySchool() {
  const [school, setSchool] = useState([]);
  const [sons,setSons] = useState([])

  const auth = useAuth()
  const ref = useRef()
  const showData = async(e) => {
    e.preventDefault()

    const response = await axios.post(`/api/school/get`,{
      school: school
    },{
      headers: {
        authorization: `Bearer ${auth.Ldata}`
      }, withCredentials: true
    })
    setSons(response.data)
  };
  return (
    <>
      <header>
        <NavBar />
        <FilterListSchool />
      </header>
      <div className="container-fluid">
        <form className="row g-3" onSubmit={showData}>
          <div className="col-auto">
            <select
              className="form-select"
              aria-label="program"
              onChange={(e) => setSchool(e.target.value)}
            >
              <option selected>Seleccione el Escuela</option>
              <option value="1">Natacion</option>
              <option value="2">Futbol</option>
              <option value="3">Baseball</option>
              <option value="4">Atletismo</option>
            </select>
          </div>
          <div className="col-auto">
            <button type="submit" className="btn btn-primary mb-3">
              Search Data
            </button>
          </div>
        </form>
      </div>
      <div className="table-responsive" ref={ref}>
      <table className="table table-striped table-hover mt-5 shadow-lg">
        <thead>
          <tr className="bg-grupo text-black ">
            <th>id</th>
            <th>Primer Nombre</th>
            <th>Segundo Nombre</th>
            <th>Primer Apellido</th>
            <th>Segundo Apellido</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(sons).map((son) => (
            <tr key={son.id_son_dougther}>
              <td>{son.id_son_dougther}</td>
              <td>{son.firstname}</td>
              <td>{son.secondname}</td>
              <td>{son.first_lastname}</td>
              <td>{son.second_lastname}</td>
              <td>
                <Link
                  to={`/Escuelas/Listar/${son.id_son_dougther}`}
                  className="btn btn-outline-primary text-center"
                >
                  Mas Informacion
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </>
  );
}
