import { motion } from "framer-motion";
import { useState } from "react";
import "../../../Stylesheet/CardHome.css";

export default function CardHome({imgRoute,Tittle,Body}) {
  return (
      <div className="card-container h-100">
        <div className="image-container">
          <img src={imgRoute} className="card-img-top" alt=""/>
        </div>
        <div className="card-content">
          <div className="card-title">
            <h3>{Tittle}</h3>
          </div>
          <div className="card-body">
            <p>
              {Body}
            </p>
          </div>
        </div>
      </div>
  );
}
