import axios from "axios";
import React, { useState } from "react";
import NavBar from "../NavBar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../Services/AuthContext";

export default function CreateMember() {
  const [CC, setCC] = useState([]);
  const [firstname, setFirstname] = useState([]);
  const [secondname, setSecondname] = useState([]);
  const [first_lastname, setFirst_lastname] = useState([]);
  const [second_lastname, setSecond_lastname] = useState([]);
  const [cellphone, setCellphone] = useState(0);
  const [gender, setGender] = useState([]);
  const [business, setBusiness] = useState([]);
  const [EPS, setEPS] = useState([]);
  const [AFT, setAFT] = useState([]);
  const [cesantias, setCesantias] = useState([]);
  const [birth, setBirth] = useState([]);
  const [admission, setAdmission] = useState([]);
  const [position, setPosition] = useState([]);
  const [species, setSpecies] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [status, setStatus] = useState([]);
  const [CC_file, setCC_file] = useState([]);
  const [typeCC_file, setTypeCC_file] = useState("");
  const [fishing_certificate, setFishingCertificate] = useState([]);
  const [typeFishing_certificate, setTypeFishing_Certificate] = useState("");
  const [familyPicture, setFamilyPicture] = useState([]);
  const [typeFamilyPicture, setTypeFamilyPicture] = useState("");

  const convertToBase64 = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setCC_file(reader.result.toString().substr(23));
      console.log(reader.result.toString().substr(23));
      setTypeCC_file(file.type);
    };
    reader.readAsDataURL(file);
  };

  const SetCertificaded = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setFishingCertificate(reader.result.toString().substr(23));
      setTypeFishing_Certificate(file.type);
    };
    reader.readAsDataURL(file);
  };

  const SetFamilyPhoto = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setFamilyPicture(reader.result.toString().substr(23));
      setTypeFamilyPicture(file.type);
    };
    reader.readAsDataURL(file);
  };

  const auth = useAuth();
  const showDatabase = async (e) => {
    e.preventDefault();

    if (
      typeCC_file === "image/png" ||
      typeFamilyPicture === "image/png" ||
      typeFishing_certificate === "image/png"
    ) {
      toast.error("Formato de Imagen no admitido (PNG)");
    } else {
      await toast.promise(
        axios.post(
          `/api/employee/create`,
          {
            CC: CC,
            firstname: firstname,
            secondname: secondname,
            first_lastname: first_lastname,
            second_lastname: second_lastname,
            cellphone: cellphone,
            gender: gender,
            business_id: business,
            EPS: EPS,
            AFT: AFT,
            cesantias: cesantias,
            date_birth: birth,
            date_admission: admission,
            position: position,
            species: species,
            status: status,
            supplier_id: supplier,
            CC_file: CC_file,
            typeCC_file: typeCC_file,
            Certificate_Fishing: fishing_certificate,
            typeFishing_certificate: typeFishing_certificate,
            familyPicture: familyPicture,
            typePicture: typeFamilyPicture,
          },
          {
            headers: {
              authorization: `Bearer ${auth.Ldata}`,
            },
            withCredentials: true,
          }
        ),
        {
          pending: "Creando Nuevo Miembro",
          success: "Miembro Creado",
          error: "Miembro No Creado",
        }
      ).then((response)=>{console.log(response)})
    }
  };

  return (
    <>
      <header>
        <NavBar />
      </header>
      <body>
        <div className="container">
          <form
            className="row mt-3 g-3 needs-validation"
            onSubmit={showDatabase}
            noValidate
          >
            <div className="col-6">
              <label for="CC" className="form-label">
                Cedula del Trabajador
              </label>
              <input
                type="number"
                className="form-control"
                id="CC"
                onChange={(e) => setCC(e.target.value)}
              />
            </div>
            <div className="col-6">
              <label for="nameId" className="form-label">
                Primer Nombre
              </label>
              <input
                type="text"
                className="form-control"
                id="nameId"
                onChange={(e) => setFirstname(e.target.value)}
                required
              />
              <div className="valid-feedback">ok</div>
              <div className="invalid-feedback">Obligatorio</div>
            </div>
            <div className="col-6">
              <label for="name2Id" className="form-label">
                Segundo Nombre
              </label>
              <input
                type="text"
                className="form-control"
                id="name2Id"
                onChange={(e) => setSecondname(e.target.value)}
                required
              />
              <div className="valid-feedback">ok</div>
              <div className="invalid-feedback">Obligatorio</div>
            </div>
            <div className="col-6">
              <label for="FLastnameId" className="form-label">
                Primer Apellido
              </label>
              <input
                type="text"
                className="form-control"
                id="FLastnameId"
                onChange={(e) => setFirst_lastname(e.target.value)}
                required
              />
              <div className="valid-feedback">ok</div>
              <div className="invalid-feedback">Obligatorio</div>
            </div>
            <div className="col-6">
              <label for="SLastnameId" className="form-label">
                Segundo Apellido
              </label>
              <input
                type="text"
                className="form-control"
                id="SFLastnameId"
                onChange={(e) => setSecond_lastname(e.target.value)}
                required
              />
              <div className="valid-feedback">ok</div>
              <div className="invalid-feedback">Obligatorio</div>
            </div>
            <div className="col-6">
              <label for="Cellphone" className="form-label">
                Numero Celular
              </label>
              <input
                type="number"
                className="form-control"
                id="Cellphone"
                onChange={(e) => setCellphone(e.target.value)}
              />
            </div>
            <div className="col-6">
              <label for="Birth" className="form-label">
                Fecha de Nacimiento
              </label>
              <input
                type="date"
                className="form-control"
                id="Birth"
                onChange={(e) => setBirth(e.target.value)}
              />
            </div>
            <div className="col-6">
              <label for="Admission" className="form-label">
                Fecha de Ingreso
              </label>
              <input
                type="date"
                className="form-control"
                id="Admission"
                onChange={(e) => setAdmission(e.target.value)}
              />
            </div>
            <div className="col-6">
              <label for="inputGender" className="form-label">
                Genero
              </label>
              <select
                className="form-select"
                id="inputGender"
                onChange={(e) => setGender(e.target.value)}
              >
                <option selected>Seleccione El genero</option>
                <option value="M">Masculino</option>
                <option value="F">Femenino</option>
              </select>
            </div>
            <div className="col-6">
              <label for="inputEPS" className="form-label">
                EPS
              </label>
              <input
                type="text"
                className="form-control"
                id="inputEPS"
                onChange={(e) => setEPS(e.target.value)}
              />
            </div>
            <div className="col-6">
              <label for="inputAFT" className="form-label">
                Pension
              </label>
              <input
                type="text"
                className="form-control"
                id="inputAFT"
                onChange={(e) => setAFT(e.target.value)}
              />
            </div>
            <div className="col-6">
              <label for="inputBusiness" className="form-label">
                Planta
              </label>
              <select
                className="form-select"
                id="inputBusiness"
                onChange={(e) => setBusiness(e.target.value)}
                required
              >
                <option selected>Seleccione La Planta</option>
                <option value="1">Cienaga</option>
                <option value="2">Buenaventura</option>
              </select>
            </div>
            <div className="col-6">
              <label for="inputPosition" className="form-label">
                Cargo
              </label>
              <select
                className="form-select"
                id="inputPosition"
                onChange={(e) => setPosition(e.target.value)}
                required
              >
                <option selected>Seleccione El Cargo</option>
                <option value="2">Operario/a</option>
                <option value="3">Pescador/a</option>
              </select>
            </div>
            <div className="col-6">
              <label for="inputSpecies" className="form-label">
                Especie
              </label>
              <select
                className="form-select"
                id="inputSpecies"
                onChange={(e) => setSpecies(e.target.value)}
                required
              >
                <option selected>Seleccione La especie Pescada</option>
                <option value="1">Jaiba</option>
                <option value="2">Langosta</option>
                <option value="9">No Aplica</option>
              </select>
            </div>
            <div className="col-6">
              <label for="inputSupplier" className="form-label">
                Proveedor
              </label>
              <select
                className="form-select"
                id="inputSupplier"
                onChange={(e) => setSupplier(e.target.value)}
              >
                <option selected>Seleccione El Proveedor</option>
                <option value="1">KEISA BUSTAMANTE</option>
                <option value="2">FROILAN HERNANDEZ</option>
                <option value="3">OMAR MIRANDA</option>
                <option value="4">ALVARO MANJAREZ</option>
                <option value="5">MARIA CRISTINA MORA</option>
                <option value="6">DEINER GUERRERO</option>
                <option value="7">ELKIN ACOSTA</option>
                <option value="8">JUAN BAUTISTA</option>
                <option value="9">CARLOS ALVAREZ MORENO</option>
                <option value="20">NO APLICA</option>
              </select>
            </div>
            <div className="col-6">
              <label for="cesantias" className="form-label">
                Cesantias
              </label>
              <input
                type="text"
                className="form-control"
                id="cesantias"
                onChange={(e) => setCesantias(e.target.value)}
              />
            </div>
            <div className="col-6">
              <label for="inputSpecies" className="form-label">
                Estatus
              </label>
              <select
                className="form-select"
                id="inputSpecies"
                onChange={(e) => setStatus(e.target.value)}
                required
              >
                <option selected>Seleccione Estatus</option>
                <option value="ACTIVO">ACTIVO</option>
                <option value="INACTIVO">INACTIVO</option>
              </select>
            </div>
            <div className="col-4">
              <label for="inputFile" className="form-label">
                Cedula de Ciudadania
              </label>
              <input
                type="file"
                className="form-control"
                onChange={(e) => convertToBase64(e)}
              />
            </div>
            <div className="col-4">
              <label for="inputCertificate" className="form-label">
                Certificado de Pesca
              </label>
              <input
                type="file"
                className="form-control"
                onChange={(e) => SetCertificaded(e)}
              />
            </div>
            <div className="col-4">
              <label for="inputPhoto" className="form-label">
                Foto Familiar
              </label>
              <input
                type="file"
                className="form-control"
                onChange={(e) => SetFamilyPhoto(e)}
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Enviar
            </button>
          </form>
        </div>
      </body>
    </>
  );
}
