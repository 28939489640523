import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NavBar from "../NavBar";
import { useAuth } from "../../Services/AuthContext";

export default function CCfile() {
  const { id } = useParams();
  const auth = useAuth();
  const [employee, setEmployee] = useState("");
  const [type, setType] = useState("");

  const showDatabase = async () => {
    await axios
      .get(`/api/employee/idfile/${id}`, {
        headers: {
          authorization: `Bearer ${auth.Ldata}`,
        },
        withCredentials: true,
      })
      .then((response) => {
        setEmployee(response.data.ccdata);
        setType(response.data.type);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    showDatabase();
  }, []);
  return (
    <>
      <header>
        <NavBar />
      </header>
      <div>
        <img
          src={`data:${type};base64,${employee}`}
          style={{ height: "600px", width: "600px" }}
        />
      </div>
    </>
  );
}
