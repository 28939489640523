import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import NavBar from "./NavBar";
import Filter from "../Views/FilterViews/Beneficiaries/Filters";
import "../../Stylesheet/Profile.css";
import axios from "axios";
import { useAuth } from "../Services/AuthContext";

export default function Profile() {
  const [son, setSon] = useState([]);
  const [navigate, setNavigate] = useState([]);
  let { id } = useParams();

  const auth = useAuth()

  const ShowData = async () => {
    try {
      const response = await axios.get(`/api/sons/${id}`, {
        headers: { authorization: `Bearer ${auth.Ldata}` },
        withCredentials: true,
      });
      console.log(response.status);
      console.log(response.data)
      setSon(response.data);
    } catch (error) {
      console.log(error);
      setNavigate(true);
    }
  };

  useEffect(() => {
    ShowData();
  }, []);
  return (
    <>
      <header>
        <NavBar />
      </header>
      <Filter />
      <body>
        {Object.values(son).map((SON) => (
          <>
            <div className="container-fluid d-flex justify-content-center">
              <div className="row">
                <div className="col-md-4">
                  <div className="card card-info h-100">
                    <h5 className="card-tittle">Informacion Personal</h5>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <h6>Identificacion:</h6> {SON.id_son_dougther}
                      </li>
                      <li className="list-group-item">
                        <h6>Primer Nombre:</h6> {SON.firstname}
                      </li>
                      <li className="list-group-item">
                        <h6>Segundo Nombre:</h6> {SON.secondname}
                      </li>
                      <li className="list-group-item">
                        <h6>Primer Apellido:</h6> {SON.first_lastname}
                      </li>
                      <li className="list-group-item">
                        <h6>Segundo Apellido:</h6> {SON.second_lastname}
                      </li>
                      <li className="list-group-item">
                        <h6>Fecha de Nacimiento:</h6> {SON.date_birth}
                      </li>
                      <li className="list-group-item"><h6>Genero:</h6> {SON.gender}</li>
                      <Link
                        className="btn btn-outline-success"
                        to={`/Miembros/Profile/${SON.CC}`}
                      >
                        Reporte Familiar
                      </Link>
                      <Link className="btn btn-outline-success"
                     to={`/Listar/Registro/${id}`}>
                        Registro Civil
                      </Link>
                      <Link className="btn btn-outline-success" to={`/Listar/Set/${id}`}>Editar</Link>
                      <Link className="btn btn-outline-success" to={`/Listar/SetFile/${id}`}>Editar Archivos</Link>
                    </ul>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="card card-info h-100">
                    <h5 className="card-tittle">Informacion Academica</h5>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <h6>Año escolar:</h6> {SON.school_year}
                      </li>
                      <li className="list-group-item">
                        <h6>Nivel escolar:</h6> {SON.scholarship}
                      </li>
                      <li className="list-group-item">
                        <h6>Actividad escolar:</h6> {SON.culminated}
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="card card-info h-100">
                    <h5 className="card-tittle">Informacion de fundacion</h5>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <h6>Programa Actual:</h6> {SON.name_program}
                      </li>
                      <li className="list-group-item">
                        <h6>Escuela Actual:</h6> {SON.id_school}
                      </li>
                      <li className="list-group-item"><h6>Apadrinado Por:</h6></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
      </body>
    </>
  );
}
