import axios from "axios";
import React, { useState } from "react";
import NavBar from "../../../Layouts/NavBar";
import FilterPrograms from "./FiltersPrograms";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../../Services/AuthContext";

export default function FilterSchool() {

  const [program, setProgram] = useState([]);
  const [scholarship, setScholarship] = useState([]);
  
  const auth = useAuth()
  const showData = async (e) => {
    e.preventDefault();

    const response = await toast.promise(
      axios.post(`/api/programs/setScholarship`,{
        program: program,scholarship: scholarship
      },{
        headers: {
          authorization: `Bearer ${auth.Ldata}`
        }, withCredentials: true
      }),{
        pending: 'Agregando...',
        success: 'Agregado Con Exito',
        error: 'Error'
      }
    )
    console.log(response)
  };
  return (
    <>
      <header>
        <NavBar />
        <FilterPrograms />
      </header>
      <div>
        <form className="row g-3" onSubmit={showData}>
          
        <div className="col-auto">
                        <select className="form-select" aria-label="Operator" onChange={(e)=>setScholarship(e.target.value)}>
                            <option selected>Seleccione Escolaridad</option>
                            <option value="KINDER">Kinder</option>
                            <option value="PREESCOLAR">Preescolar</option>
                            <option value="PRIMARIA">Primaria</option>
                            <option value="SECUNDARIA">Secundaria</option>
                            <option value="PROFESIONAL">Profesional</option>
                            <option value="OTRO">Otro</option>
                        </select>
                    </div>
          <div className="col-auto">
                        <select className="form-select" aria-label="Operator" onChange={(e)=>setProgram(e.target.value)}>
                            <option selected>Seleccione Programa</option>
                            <option value="1">Pescando Talentos</option>
                            <option value="2">Viste la pesca</option>
                            <option value="3">Olas del Futuro</option>
                            <option value="4">Tiburon Mama</option>
                            <option value="5">A mar Navidad</option>
                        </select>
                    </div>
          <div className="col-auto">
            <button type="submit" className="btn btn-primary mb-3">
              Search Data
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
