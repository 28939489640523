import React, { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Link } from "react-router-dom";
import Cookie from "js-cookie";
import NavBar from "../../../Layouts/NavBar";
import Filter from "./Filters";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../../Services/AuthContext";

export default function BusinessGenderAge() {
  const [Sons, setSons] = useState([]);
  const [Age, setAge] = useState([]);
  const [gender, setGender] = useState("");
  const [business, setBusiness] = useState("");
  const [Option, setOption] = useState("");
  const ref = useRef();
  const auth = useAuth()

  const handlePrint = useReactToPrint({
    content: () => ref.current,
    documentTitle: "Reporte ",
  });
  const showData = async (e) => {
    e.preventDefault();
    try {
      const response = await toast.promise(
        axios.post(
          `/api/sons/businessGenderAge`,
          {
            age: Age,
            operator: Option,
            gender:gender,
            business: business,
          },
          {
            headers: {
              Authorization: `Bearer ${auth.Ldata}`,
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        ),
        {
          pending: 'Filtrando...',
          success: 'Filtro Exitoso',
          error: 'Error'
        }
      )
      console.log(response.data);
      setSons(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    showData();
  }, []);

  return (
    <div>
      <header>
        <NavBar />
        <Filter />
      </header>
      <div className="container-fluid">
        <form className="row g-3" onSubmit={showData}>
          <div className="col-auto">
            <label for="inputAge" className="visually-hidden">
              Edad
            </label>
            <input
              type="text"
              className="form-control"
              id="inputAge"
              placeholder="Age"
              onChange={(e) => setAge(e.target.value)}
            />
          </div>
          <div className="col-auto">
            <select
              className="form-select"
              aria-label="Operator"
              onChange={(e) => setOption(e.target.value)}
            >
              <option selected>Opcion de edad</option>
              <option value="<">Menor de</option>
              <option value=">">Mayor de</option>
              <option value="=">Igual a</option>
            </select>
          </div>
          <div className="col-auto">
            <select
              className="form-select"
              aria-label="gender"
              onChange={(e) => setGender(e.target.value)}
            >
              <option selected>Genero</option>
              <option value="M">Masculino</option>
              <option value="F">Femenino</option>
            </select>
          </div>
          <div className="col-auto">
            <select
              className="form-select"
              aria-label="Business"
              onChange={(e) => setBusiness(e.target.value)}
            >
              <option selected>Planta</option>
              <option value="1">Planta Cienaga</option>
              <option value="2">Planta Buenaventura</option>
            </select>
          </div>
          <div className="col-auto">
            <button type="submit" className="btn btn-primary mb-3">
              Search Data
            </button>
          </div>
        </form>
      </div>
      <div className="data-container justify-content-center align-items-center">
        <spam>Total Filtrado: {Sons?.length > 0 ? Sons?.length : 0}</spam>
        <button onClick={handlePrint} className="btn btn-primary mb-3">
          Descargar Reporte
        </button>
      </div>
      <div className="table-responsive" ref={ref}>
        <table className="table table-striped table-hover mt-5 shadow-lg">
          <thead>
            <tr className="bg-grupo text-black ">
              <th>Identificacion - Beneficiario</th>
              <th>Nombre - Beneficiario</th>
              <th>Identificacion - Miembro</th>
              <th>Nombre - Miembro</th>
              <th>Contacto</th>
            </tr>
          </thead>
          <tbody>
            {Object.values(Sons).map((son) => (
              <tr key={son.id_son_dougther}>
                <td>{son.id_son_dougther}</td>
                <td>
                  {son.firstname +
                    " " +
                    son.secondname +
                    " " +
                    son.first_lastname +
                    " " +
                    son.second_lastname}
                </td>
                <td>{son.CC}</td>
                <td>
                  {son.fathername +
                    " " +
                    son.fathersecondname +
                    " " +
                    son.fatherfirst_lastname +
                    " " +
                    son.fathersecond_lastname}
                </td>
                <td>{son.cellphone}</td>
                <td>
                  <Link
                    to={`/Listar/businessGenderAge/${son.id_son_dougther}`}
                    className="btn btn-outline-primary text-center"
                  >
                    Mas Informacion
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
