import React, { useState } from "react";
import NavBar from "../NavBar";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../Services/AuthContext";

export default function CreateSon() {
  const [id, setId] = useState(0);
  const [CC, setCC] = useState(0);
  const [firstname, setFirstname] = useState([]);
  const [secondname, setSecondname] = useState([]);
  const [first_lastname, setFirst_lastname] = useState([]);
  const [second_lastname, setSecond_lastname] = useState([]);
  const [school_year, setSchool_year] = useState(0);
  const [scholarship, setScholarship] = useState([]);
  const [culminated, setCulminated] = useState([]);
  const [gender, setGender] = useState([]);
  const [date_birth, setDate_birth] = useState([]);
  const [register, setRegister] = useState([]);
  const [typeRegister, setTypeRegister] = useState('')

  const auth = useAuth()

  const convertToBase64 = (e) => {
    const file = e.target.files[0];
    setTypeRegister(file.type)

    const reader = new FileReader();
    reader.onloadend = () => {
      setRegister(reader.result.toString().substr(22));
    };
    reader.readAsDataURL(file);
  };

  const showDatabase = async (e) => {
    e.preventDefault();

    if(typeRegister === "image/png"){
      toast.error("Formato de Imagen no admitido (PNG)")
    }else{
      await toast.promise(
        axios.post(
          `/api/sons/create`,
          {
            id: id,
            CC: CC,
            firstname: firstname,
            secondname: secondname,
            first_lastname: first_lastname,
            second_lastname: second_lastname,
            scholarship: scholarship,
            school_year: school_year,
            culminated: culminated,
            gender: gender,
            date_birth: date_birth,
            register: register,
            typeRegister: typeRegister
          },
          {
            headers: {
              authorization: `Bearer ${auth.Ldata}`,
            },
            withCredentials: true,
          }
        ),{
          pending: 'Creando Nuevo Beneficiario...',
          success: 'Beneficiario Creado',
          error: 'Beneficiario No creado'
        }
      ).then((response)=>{ console.log(response) })
    }
  };

  return (
    <>
      <header>
        <NavBar />
      </header>
      <body>
        <div className="container">
          <form
            className="row mt-3 g-3 needs-validation"
            onSubmit={showDatabase}
            noValidate
          >
            <div className="col-6">
              <label for="id" className="form-label">
                Identificacion del Beneficiario
              </label>
              <input
                type="number"
                className="form-control"
                id="id"
                onChange={(e) => setId(e.target.value)}
                required
              />
              <div className="invalid-feedback">Identificacion requerida o ya existente</div>
            </div>
            <div className="col-6">
              <label for="CC" className="form-label">
                Cedula del Trabajador
              </label>
              <input
                type="number"
                className="form-control"
                id="CC"
                onChange={(e) => setCC(e.target.value)}
                required
              />
              <div className="invalid-feedback"></div>
            </div>
            <div className="col-6">
              <label for="nameId" className="form-label">
                Primer Nombre
              </label>
              <input
                type="text"
                className="form-control"
                id="nameId"
                onChange={(e) => setFirstname(e.target.value)}
                required
              />
              <div className="valid-feedback">ok</div>
              <div className="invalid-feedback">Obligatorio</div>
            </div>
            <div className="col-6">
              <label for="name2Id" className="form-label">
                Segundo Nombre
              </label>
              <input
                type="text"
                className="form-control"
                id="name2Id"
                onChange={(e) => setSecondname(e.target.value)}
                required
              />
              <div className="valid-feedback">ok</div>
              <div className="invalid-feedback">Obligatorio</div>
            </div>
            <div className="col-6">
              <label for="FLastnameId" className="form-label">
                Primer Apellido
              </label>
              <input
                type="text"
                className="form-control"
                id="FLastnameId"
                onChange={(e) => setFirst_lastname(e.target.value)}
                required
              />
              <div className="valid-feedback">ok</div>
              <div className="invalid-feedback">Obligatorio</div>
            </div>
            <div className="col-6">
              <label for="SLastnameId" className="form-label">
                Segundo Apellido
              </label>
              <input
                type="text"
                className="form-control"
                id="SFLastnameId"
                onChange={(e) => setSecond_lastname(e.target.value)}
                required
              />
              <div className="valid-feedback">ok</div>
              <div className="invalid-feedback">Obligatorio</div>
            </div>
            <div className="col-4">
              <label for="Birth" className="form-label">
                Fecha de Nacimiento
              </label>
              <input
                type="date"
                className="form-control"
                id="Birth"
                onChange={(e) => setDate_birth(e.target.value)}
              />
            </div>
            <div className="col-4">
              <label for="inputScholarship" className="form-label">
                Escolaridad
              </label>
              <select
                className="form-select"
                id="inputScholarship"
                onChange={(e) => setScholarship(e.target.value)}
                required
              >
                <option selected>Seleccione Nivel de Escolaridad</option>
                <option value="Kinder">Kinder</option>
                <option value="Preescolar">Preescolar</option>
                <option value="Primaria">Primaria</option>
                <option value="Secundaria">Secundaria</option>
                <option value="Tecnica">Tecnica</option>
                <option value="Profesional">Profesional</option>
                <option value="Otro">Otro</option>
              </select>
            </div>
            <div className="col-4">
              <label for="inputCulminated" className="form-label">
                Estado de Escolaridad
              </label>
              <select
                className="form-select"
                id="inputCulminated"
                required
                onChange={(e) => setCulminated(e.target.value)}
              >
                <option selected>Seleccione Estado</option>
                <option value="SI">Culminado</option>
                <option value="NO">No Culminado</option>
                <option value="Proceso">En proceso</option>
              </select>
            </div>
            <div className="col-4">
              <label for="ScholarYear" className="form-label">
                Año Escolar
              </label>
              <input
                type="number"
                className="form-control"
                id="ScholarYear"
                onChange={(e) => setSchool_year(e.target.value)}
              />
            </div>
            <div className="col-4">
              <label for="validationDefault04" className="form-label">
                Genero
              </label>
              <select
                className="form-select"
                id="validationDefault04"
                onChange={(e) => setGender(e.target.value)}
                required
              >
                <option selected>Seleccione El genero</option>
                <option value="M">Masculino</option>
                <option value="F">Femenino</option>
              </select>
            </div>
            <div className="col-4">
              <label for="inputFile" className="form-label">
                Registro Civil
              </label>
              <input
                type="file"
                className="form-control"
                onChange={(e) => convertToBase64(e)}
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Enviar
            </button>
          </form>
        </div>
      </body>
    </>
  );
}
