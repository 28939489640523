import React, { useEffect, useState } from "react";
import { useAuth } from "../../Services/AuthContext";
import { useParams } from "react-router-dom";
import axios from "axios";
import NavBar from "../NavBar";
import { toast } from "react-toastify";

export default function UpdateMembers() {
  const { id } = useParams();
  const auth = useAuth();

  const [firstname, setFirstname] = useState("");
  const [secondname, setSecondname] = useState("");
  const [first_lastname, setFirst_lastname] = useState("");
  const [second_lastname, setSecond_lastname] = useState("");
  const [cellphone, setCellphone] = useState(0);
  const [gender, setGender] = useState("");
  const [business, setBusiness] = useState("");
  const [EPS, setEPS] = useState("");
  const [AFT, setAFT] = useState("");
  const [cesantias, setCesantias] = useState("");
  const [birth, setBirth] = useState("");
  const [admission, setAdmission] = useState("");
  const [position, setPosition] = useState("");
  const [species, setSpecies] = useState("");
  const [supplier, setSupplier] = useState("");
  const [status, setStatus] = useState("");

  const [sfirstname, ssetfirstname] = useState("");
  const [ssecondname, ssetSecondname] = useState("");
  const [sfirst_lastname, ssetFirst_lastname] = useState("");
  const [ssecond_lastname, ssetSecond_lastname] = useState("");
  const [scellphone, ssetCellphone] = useState(0);
  const [sgender, ssetGender] = useState("");
  const [sbusiness, ssetBusiness] = useState("");
  const [sEPS, ssetEPS] = useState("");
  const [sAFT, ssetAFT] = useState("");
  const [scesantias, ssetCesantias] = useState("");
  const [sbirth, ssetBirth] = useState("");
  const [sadmission, ssetAdmission] = useState("");
  const [sposition, ssetPosition] = useState("");
  const [sspecies, ssetSpecies] = useState("");
  const [ssupplier, ssetSupplier] = useState("");
  const [sstatus, ssetStatus] = useState("");

  const SendUpdate = async (e) => {
    e.preventDefault();
    try {
      console.log(auth.Ldata);
      await toast.promise(
        axios.put(
          `/api/employee/setEmployee/${id}`,
          {
            firstname: sfirstname ? sfirstname : firstname,
            secondname: ssecondname ? ssecondname : secondname,
            first_lastname: sfirst_lastname ? sfirst_lastname : first_lastname,
            second_lastname: ssecond_lastname
              ? ssecond_lastname
              : second_lastname,
            cellphone: scellphone ? scellphone : cellphone,
            business_id: sbusiness ? sbusiness : business,
            gender: sgender ? sgender : gender,
            EPS: sEPS ? sEPS : EPS,
            AFT: sAFT ? sAFT : AFT,
            cesantias: scesantias ? scesantias : cesantias,
            date_birth: sbirth ? sbirth : birth,
            date_admission: sadmission ? sadmission : admission,
            position: sposition ? sposition : position,
            status: sstatus ? sstatus : status,
            species_id: sspecies ? sspecies : species,
            supplier: ssupplier ? ssupplier : supplier,
          },
          {
            headers: {
              authorization: `Bearer ${auth.Ldata}`,
            },
          }
        ),{
          pending: 'Editando informacion',
          success: 'Informacion editada',
          error: 'Error al editar'
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const showDatabase = async () => {
    console.log(auth.Ldata);
    await axios
      .get(`/api/employee/getuserPUT/${id}`, {
        headers: {
          authorization: `Bearer ${auth.Ldata}`,
        },
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data.firstname);
        console.log(response.data[0].firstname);
        setFirstname(response.data[0].firstname);
        setSecondname(response.data[0].secondname);
        setFirst_lastname(response.data[0].first_lastname);
        setSecond_lastname(response.data[0].second_lastname);
        setCellphone(response.data[0].cellphone);
        setGender(response.data[0].gender);
        setBusiness(response.data[0].business_id);
        setEPS(response.data[0].EPS);
        setAFT(response.data[0].AFT);
        setCesantias(response.data[0].cesantias);
        setBirth(response.data[0].date_birth);
        setAdmission(response.data[0].date_admission);
        setPosition(response.data[0].position);
        setSpecies(response.data[0].species_id);
        setSupplier(response.data[0].supplier_id);
        setStatus(response.data[0].status);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    showDatabase();
    SendUpdate();
  }, []);
  return (
    <>
      <header>
        <NavBar />
      </header>
      <body>
        <div className="container">
          <form
            className="row mt-3 g-3 needs-validation"
            onSubmit={SendUpdate}
            noValidate
          >
            <div className="col-6">
              <label for="nameId" className="form-label">
                Primer Nombre
              </label>
              <input
                type="text"
                className="form-control"
                id="nameId"
                onChange={(e) => ssetfirstname(e.target.value)}
                required
              />
              <div className="valid-feedback">ok</div>
              <div className="invalid-feedback">Obligatorio</div>
            </div>
            <div className="col-6">
              <label for="name2Id" className="form-label">
                Segundo Nombre
              </label>
              <input
                type="text"
                className="form-control"
                id="name2Id"
                onChange={(e) => ssetSecondname(e.target.value)}
                required
              />
              <div className="valid-feedback">ok</div>
              <div className="invalid-feedback">Obligatorio</div>
            </div>
            <div className="col-6">
              <label for="FLastnameId" className="form-label">
                Primer Apellido
              </label>
              <input
                type="text"
                className="form-control"
                id="FLastnameId"
                onChange={(e) => ssetFirst_lastname(e.target.value)}
                required
              />
              <div className="valid-feedback">ok</div>
              <div className="invalid-feedback">Obligatorio</div>
            </div>
            <div className="col-6">
              <label for="SLastnameId" className="form-label">
                Segundo Apellido
              </label>
              <input
                type="text"
                className="form-control"
                id="SFLastnameId"
                onChange={(e) => ssetSecond_lastname(e.target.value)}
                required
              />
              <div className="valid-feedback">ok</div>
              <div className="invalid-feedback">Obligatorio</div>
            </div>
            <div className="col-6">
              <label for="Cellphone" className="form-label">
                Numero Celular
              </label>
              <input
                type="number"
                className="form-control"
                id="Cellphone"
                onChange={(e) => ssetCellphone(e.target.value)}
              />
            </div>
            <div className="col-6">
              <label for="Birth" className="form-label">
                Fecha de Nacimiento
              </label>
              <input
                type="date"
                className="form-control"
                id="Birth"
                onChange={(e) => ssetBirth(e.target.value)}
              />
            </div>
            <div className="col-6">
              <label for="Admission" className="form-label">
                Fecha de Ingreso
              </label>
              <input
                type="date"
                className="form-control"
                id="Admission"
                onChange={(e) => ssetAdmission(e.target.value)}
              />
            </div>
            <div className="col-6">
              <label for="inputGender" className="form-label">
                Genero
              </label>
              <select
                className="form-select"
                id="inputGender"
                onChange={(e) => ssetGender(e.target.value)}
              >
                <option selected>Seleccione El genero</option>
                <option value="M">Masculino</option>
                <option value="F">Femenino</option>
              </select>
            </div>
            <div className="col-6">
              <label for="inputEPS" className="form-label">
                EPS
              </label>
              <input
                type="text"
                className="form-control"
                id="inputEPS"
                onChange={(e) => ssetEPS(e.target.value)}
              />
            </div>
            <div className="col-6">
              <label for="inputAFT" className="form-label">
                Pension
              </label>
              <input
                type="text"
                className="form-control"
                id="inputAFT"
                onChange={(e) => ssetAFT(e.target.value)}
              />
            </div>
            <div className="col-6">
              <label for="inputBusiness" className="form-label">
                Planta
              </label>
              <select
                className="form-select"
                id="inputBusiness"
                onChange={(e) => ssetBusiness(e.target.value)}
                required
              >
                <option selected>Seleccione La Planta</option>
                <option value="1">Cienaga</option>
                <option value="2">Buenaventura</option>
              </select>
            </div>
            <div className="col-6">
              <label for="inputPosition" className="form-label">
                Cargo
              </label>
              <select
                className="form-select"
                id="inputPosition"
                onChange={(e) => ssetPosition(e.target.value)}
                required
              >
                <option selected>Seleccione El Cargo</option>
                <option value="2">Operario/a</option>
                <option value="3">Pescador/a</option>
              </select>
            </div>
            <div className="col-6">
              <label for="inputSpecies" className="form-label">
                Especie
              </label>
              <select
                className="form-select"
                id="inputSpecies"
                onChange={(e) => ssetSpecies(e.target.value)}
                required
              >
                <option selected>Seleccione La especie Pescada</option>
                <option value="1">Jaiba</option>
                <option value="2">Langosta</option>
                <option value="9">No Aplica</option>
              </select>
            </div>
            <div className="col-6">
              <label for="inputSupplier" className="form-label">
                Proveedor
              </label>
              <select
                className="form-select"
                id="inputSupplier"
                onChange={(e) => ssetSupplier(e.target.value)}
              >
                <option selected>Seleccione El Proveedor</option>
                <option value="1">KEISA BUSTAMANTE</option>
                <option value="2">FROILAN HERNANDEZ</option>
                <option value="3">OMAR MIRANDA</option>
                <option value="4">ALVARO MANJAREZ</option>
                <option value="5">MARIA CRISTINA MORA</option>
                <option value="6">DEINER GUERRERO</option>
                <option value="7">ELKIN ACOSTA</option>
                <option value="8">JUAN BAUTISTA</option>
                <option value="9">CARLOS ALVAREZ MORENO</option>
                <option value="20">NO APLICA</option>
              </select>
            </div>
            <div className="col-6">
              <label for="cesantias" className="form-label">
                Cesantias
              </label>
              <input
                type="text"
                className="form-control"
                id="cesantias"
                onChange={(e) => ssetCesantias(e.target.value)}
              />
            </div>
            <div className="col-6">
              <label for="inputStatus" className="form-label">
                Estatus
              </label>
              <select
                className="form-select"
                id="inputStatus"
                onChange={(e) => ssetStatus(e.target.value)}
                required
              >
                <option selected>Seleccione Estatus</option>
                <option value="ACTIVO">ACTIVO</option>
                <option value="INACTIVO">INACTIVO</option>
              </select>
            </div>
            <button type="submit" className="btn btn-primary">
              Enviar
            </button>
          </form>
        </div>
      </body>
    </>
  );
}
