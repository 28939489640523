import React from "react";
import NavBar from "../../../Layouts/NavBar";
import FilterListSchool from "./FilterListsSchool";

export default function(){
    return(
        <>
        <header>
            <NavBar />
            <FilterListSchool />
        </header>
        </>
    )
}