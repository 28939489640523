import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import NavBar from "../../../Layouts/NavBar";
import FilterPrograms from "./FiltersPrograms";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useAuth} from "../../../Services/AuthContext"

export default function AddSonById() {
  const [program, setProgram] = useState("");
  const [id, setId] = useState("");

  const auth = useAuth()
  const showData = async (e) => {
    e.preventDefault();

    const response = await toast.promise(

      axios.post(
    `/api/programs/setId`,
        {
          program_id: program,
          idson: id,
        },
        {
          headers: {
            authorization: `Bearer ${auth.Ldata}`,
          },
          withCredentials: true,
        }
      ),{
        pending: 'Agregando a Programa...',
        success: 'Agregado Con Exito',
        error: 'Error'
      }
    )
    console.log(response.data)
  };
  return (
    <>
      <header>
        <NavBar />
        <FilterPrograms />
      </header>
      <div>
        <form className="row g-3" onSubmit={showData}>
          <div className="col-auto input-field">
            <label for="inputId" className="visually-hidden">
              Edad
            </label>
            <input
              type="text"
              className="form-control"
              id="inputId"
              placeholder="Identificacion"
              onChange={(e) => setId(e.target.value)}
            />
          </div>
          <div className="col-auto">
            <select
              className="form-select"
              aria-label="Operator"
              onChange={(e) => setProgram(e.target.value)}
            >
              <option selected>Seleccione el programa</option>
              <option value="1">Pescando Talentos</option>
              <option value="2">Viste la pesca</option>
              <option value="3">Olas del futuro</option>
              <option value="4">Tiburon Mamá</option>
              <option value="5">A mar Navidad</option>
            </select>
          </div>
          <div className="col-auto">
            <button type="submit" className="btn btn-primary mb-3">
              Search Data
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
