import axios from "axios";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import NavBar from "../NavBar";
import { useAuth } from "../../Services/AuthContext";

export default function FamilyPhoto() {
  const { id } = useParams();
  const auth = useAuth();
  const [photo, setPhoto] = useState("");
  const [type, setType] = useState("");

  const showDatabase = async () => {
    await axios
      .get(`/api/employee/familyphoto/${id}`, {
        headers: {
          authorization: `Bearer ${auth.Ldata}`,
        },
        withCredentials: true,
      })
      .then((response) => {
        setPhoto(response.data.picturedata);
        setType(response.data.type);
      });
  };
  useEffect(() => {
    showDatabase();
  });

  return (
    <>
      <header>
        <NavBar />
      </header>
      <div>
        <img
          src={`data:${type};base64,${photo}`}
          style={{ height: "600px", width: "600px" }}
        />
      </div>
    </>
  );
}
