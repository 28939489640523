import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import jwtDecode from "jwt-decode"
import { toast } from "react-toastify";

export const RequireAuth = ({children}) =>{
    const auth = useAuth()
    
    let decode = {}
    if(!auth.Ldata){
        console.log("im here")
        auth.setAuthenticate(false)
        toast.warning('Sesion Finalizada')
        return <Navigate to={"/signin"}/>
    }else{
        decode = jwtDecode(auth.Ldata)
        if(decode.exp * 1000 < Date.now()){
            auth.setAuthenticate(false)
        }else{
            auth.setAuthenticate(true)
        }
    }
    
    return children
}