import React, { Fragment } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AllBeneficiaries from "./components/Views/FilterViews/Beneficiaries/AllBeneficiaries";
import Home from "./components/Views/Home";
import Beneficiarios from "./components/Views/Beneficiarios";
import Gender from "./components/Views/FilterViews/Beneficiaries/Gender";
import Edad from "./components/Views/FilterViews/Beneficiaries/Age";
import Business from "./components/Views/FilterViews/Beneficiaries/Business";
import BusinessGender from "./components/Views/FilterViews/Beneficiaries/BusinessGender";
import GenderAge from "./components/Views/FilterViews/Beneficiaries/GenderAge";
import BusinessGenderAge from "./components/Views/FilterViews/Beneficiaries/BusinessGenderAge";
import Program from "./components/Views/FilterViews/Beneficiaries/Program";
import Profile from "./components/Layouts/Profile";
import Programs from "./components/Programs";
import FilterAge from "./components/Views/FilterViews/Programs/FilterAge";
import FilterSchool from "./components/Views/FilterViews/Programs/FilterSchool";
import FilterByAge from "./components/Views/FilterViews/Schools/FilterByAge";
import FilterByScholarship from "./components/Views/FilterViews/Schools/FilterByScholarship";
import School from "./components/Views/FilterViews/Schools/School";
import Comitte from "./components/Views/FilterViews/Comitte/Comitte";
import Member from "./components/Views/FilterViews/Members/Member";
import MemberGender from "./components/Views/FilterViews/Members/Gender";
import MemberBusiness from "./components/Views/FilterViews/Members/Business";
import MemberBusinessGender from "./components/Views/FilterViews/Members/BusinessGender";
import MemberSpecies from "./components/Views/FilterViews/Members/Species";
import MemberSpeciesBussiness from "./components/Views/FilterViews/Members/SpeciesBusiness";
import MemberPosition from "./components/Views/FilterViews/Members/Position";
import MemberPositionBusiness from "./components/Views/FilterViews/Members/PositionBusiness";
import FilterByprograms from "./components/Views/FilterViews/Programs/FilterByPrograms";
import ProfileEmployee from "./components/Layouts/ProfileEmployee";
import ListBySchool from "./components/Views/FilterViews/Schools/ListBySchool";
import CoupleCommitte from "./components/Views/FilterViews/Comitte/CoupleCommitte";
import RegisterFile from "./components/Layouts/Files/RegisterFile";
import SchoolFile from "./components/Layouts/Files/SchoolFile";
import CCfile from "./components/Layouts/Files/CCFile";
import CreateSon from "./components/Layouts/CreateData/CreateSons";
import CreateMember from "./components/Layouts/CreateData/CreateMember";
import Scholarship from "./components/Views/FilterViews/Beneficiaries/Scholarship";
import ScholarshipGender from "./components/Views/FilterViews/Beneficiaries/ScholarshipGender";
import ScholarshipGenderBusiness from "./components/Views/FilterViews/Beneficiaries/ScholarShipGenderBusiness";
import SpecieAge from "./components/Views/FilterViews/Beneficiaries/SpeciesAge";
import SpecieGender from "./components/Views/FilterViews/Beneficiaries/SpeciesGender";
import SpecieScholarship from "./components/Views/FilterViews/Beneficiaries/SpeciesScholarship";
import SonById from "./components/Views/FilterViews/Beneficiaries/SonsById";
import NotFound from "./components/Layouts/NotFound";
import RangeAge from "./components/Views/FilterViews/Beneficiaries/FilterByRange";
import CreateSpouse from "./components/Layouts/CreateData/CreateSpouse";
import AddSonById from "./components/Views/FilterViews/Programs/AddSonById";
import FishingCertificate from "./components/Layouts/Files/FishingCertificate";
import FamilyPhoto from "./components/Layouts/Files/FamilyPhoto";
import AddById from "./components/Views/FilterViews/Schools/FilterById";
import CreateCommitte from "./components/Layouts/CreateData/CreateCommitte";
import CreateVolunteer from "./components/Layouts/CreateData/CreateVolunteer";
import { AuthProvider } from "./components/Services/AuthContext";
import { RequireAuth } from "./components/Services/RouteProtected";
import VolunteerCommitte from "./components/Views/FilterViews/Comitte/VolunteerComitte";
import InactivityBusiness from "./components/Views/FilterViews/Members/InactivityBusiness";
import Inactivity from "./components/Views/FilterViews/Members/Inactive";
import Supplier from "./components/Views/FilterViews/Members/Supplier";
import SupplierByID from "./components/Views/FilterViews/Members/SupplierById";
import Volunteers from "./components/Views/FilterViews/Members/Volunteer";
import Login from "./components/Layouts/Access/Login";
import UpdateMembers from "./components/Layouts/Update/UpdateMembers";
import CardHome from "./components/Layouts/CardHome/CardHome";
import UpdateFilesEmployees from "./components/Layouts/Update/UpdateFilesEmployees";
import UpdateSons from "./components/Layouts/Update/UpdateSons";
import UpdateFileSons from "./components/Layouts/Update/UpdateFilesSons";
import MemberCouple from "./components/Views/FilterViews/Members/Couples";
import ScholarshipByYear from "./components/Views/FilterViews/Beneficiaries/ScholarshipByYear";

export default function AppRoutes() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Fragment>
          <Routes>
            <Route path="/signin" element={<Login />} />
            <Route path="/" element={<Home />} />
            <Route path="/test" element={<CardHome />} />
            {/*Private Routes*/}
            <Route
              path="/Crear/Beneficiario"
              element={
                <RequireAuth>
                  <CreateSon />
                </RequireAuth>
              }
            />
            <Route
              path="/Crear/Miembro"
              element={
                <RequireAuth>
                  <CreateMember />
                </RequireAuth>
              }
            />
            <Route
              path="/Miembros"
              element={
                <RequireAuth>
                  <Member />
                </RequireAuth>
              }
            />
            <Route
              path="/Miembros/Profile/:id"
              element={
                <RequireAuth>
                  <ProfileEmployee />
                </RequireAuth>
              }
            />
            <Route
              path="/Miembros/gender"
              element={
                <RequireAuth>
                  <MemberGender />
                </RequireAuth>
              }
            />
            <Route
              path="/Miembros/gender/:id"
              element={
                <RequireAuth>
                  <ProfileEmployee />
                </RequireAuth>
              }
            />
            <Route
              path="/Miembros/business"
              element={
                <RequireAuth>
                  <MemberBusiness />
                </RequireAuth>
              }
            />
            <Route
              path="/Miembros/business/:id"
              element={
                <RequireAuth>
                  <ProfileEmployee />
                </RequireAuth>
              }
            />
            <Route
              path="/Miembros/businessGender"
              element={
                <RequireAuth>
                  <MemberBusinessGender />
                </RequireAuth>
              }
            />
            <Route
              path="/Miembros/businessGender/:id"
              element={
                <RequireAuth>
                  <ProfileEmployee />
                </RequireAuth>
              }
            />
            <Route
              path="/Miembros/species"
              element={
                <RequireAuth>
                  <MemberSpecies />
                </RequireAuth>
              }
            />
            <Route
              path="/Miembros/species/:id"
              element={
                <RequireAuth>
                  <ProfileEmployee />
                </RequireAuth>
              }
            />
            <Route
              path="/Miembros/SpeciesBusiness"
              element={
                <RequireAuth>
                  <MemberSpeciesBussiness />
                </RequireAuth>
              }
            />
            <Route
              path="/Miembros/SpeciesBusiness/:id"
              element={
                <RequireAuth>
                  <ProfileEmployee />
                </RequireAuth>
              }
            />
            <Route
              path="/Miembros/couple"
              element={
                <RequireAuth>
                  <MemberCouple />
                </RequireAuth>
              }
            />
            <Route
              path="/Miembros/Position"
              element={
                <RequireAuth>
                  <MemberPosition />
                </RequireAuth>
              }
            />
            <Route
              path="/Miembros/Position/:id"
              element={
                <RequireAuth>
                  <ProfileEmployee />
                  <MemberCouple />
                </RequireAuth>
              }
            />
            <Route
              path="/Miembros/PositionBusiness"
              element={
                <RequireAuth>
                  <MemberPositionBusiness />
                </RequireAuth>
              }
            />
            <Route
              path="/Miembros/PositionBusiness/:id"
              element={
                <RequireAuth>
                  <ProfileEmployee />
                </RequireAuth>
              }
            />
            <Route
              path="/Miembros/CC/:id"
              element={
                <RequireAuth>
                  <CCfile />
                </RequireAuth>
              }
            />
            <Route
              path="/Miembros/Certificado/:id"
              element={
                <RequireAuth>
                  <FishingCertificate />
                </RequireAuth>
              }
            />
            <Route
              path="/Miembros/Foto/:id"
              element={
                <RequireAuth>
                  <FamilyPhoto />
                </RequireAuth>
              }
            />
            <Route
              path="/Miembros/Set/:id"
              element={
                <RequireAuth>
                  <UpdateMembers />
                </RequireAuth>
              }
            />
            <Route
              path="/Miembros/SetFiles/:id"
              element={
                <RequireAuth>
                  <UpdateFilesEmployees />
                </RequireAuth>
              }
            />
            {/*Beneficiarios */}
            <Route
              path="/Listar"
              element={
                <RequireAuth>
                  <Beneficiarios />
                </RequireAuth>
              }
            />
            <Route
              path="/Listar/all"
              element={
                <RequireAuth>
                  <AllBeneficiaries />
                </RequireAuth>
              }
            />
            <Route
              path="/Listar/:id"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route
              path="/Listar/SearchId"
              element={
                <RequireAuth>
                  <SonById />
                </RequireAuth>
              }
            />
            <Route
              path="/Listar/business"
              element={
                <RequireAuth>
                  <Business />
                </RequireAuth>
              }
            />
            <Route
              path="/Listar/business/:id"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route
              path="/Listar/businessGender"
              element={
                <RequireAuth>
                  <BusinessGender />
                </RequireAuth>
              }
            />
            <Route
              path="/Listar/businessGender/:id"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route
              path="/Listar/gender"
              element={
                <RequireAuth>
                  <Gender />
                </RequireAuth>
              }
            />
            <Route
              path="/Listar/gender/:id"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route
              path="/Listar/genderAge"
              element={
                <RequireAuth>
                  <GenderAge />
                </RequireAuth>
              }
            />
            <Route
              path="/Listar/genderAge/:id"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route
              path="/Listar/businessGenderAge"
              element={
                <RequireAuth>
                  <BusinessGenderAge />
                </RequireAuth>
              }
            />
            <Route
              path="/Listar/businessGenderAge/:id"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route
              path="/Listar/edad"
              element={
                <RequireAuth>
                  <Edad />
                </RequireAuth>
              }
            />
            <Route
              path="/Listar/Range"
              element={
                <RequireAuth>
                  <RangeAge />
                </RequireAuth>
              }
            />
            <Route
              path="/Listar/edad/:id"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route
              path="/Listar/programa"
              element={
                <RequireAuth>
                  <Program />
                </RequireAuth>
              }
            />
            <Route
              path="/Listar/programa/:id"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route
              path="/Listar/Scholarship"
              element={
                <RequireAuth>
                  <Scholarship />
                </RequireAuth>
              }
            />
            <Route
              path="/Listar/Scholarship/:id"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route
              path="/Listar/scholarshipYear"
              element={
                <RequireAuth>
                  <ScholarshipByYear />
                </RequireAuth>
              }
            />
            <Route
              path="/Listar/ScholarshipGender"
              element={
                <RequireAuth>
                  <ScholarshipGender />
                </RequireAuth>
              }
            />
            <Route
              path="/Listar/ScholarshipGender/:id"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route
              path="/Listar/ScholarshipGenderBusiness"
              element={
                <RequireAuth>
                  <ScholarshipGenderBusiness />
                </RequireAuth>
              }
            />
            <Route
              path="/Listar/ScholarshipGenderBusiness/:id"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route
              path="/Listar/Specieage"
              element={
                <RequireAuth>
                  <SpecieAge />
                </RequireAuth>
              }
            />
            <Route
              path="/Listar/Specieage/:id"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route
              path="/Listar/SpecieGender"
              element={
                <RequireAuth>
                  <SpecieGender />
                </RequireAuth>
              }
            />
            <Route
              path="/Listar/SpecieGender/:id"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route
              path="/Listar/SpecieScholarship"
              element={
                <RequireAuth>
                  <SpecieScholarship />
                </RequireAuth>
              }
            />
            <Route
              path="/Listar/SpecieScholarship/:id"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route
              path="/Listar/Registro/:id"
              element={
                <RequireAuth>
                  <RegisterFile />
                </RequireAuth>
              }
            />
            <Route
              path="/Listar/School/:id"
              element={
                <RequireAuth>
                  <SchoolFile />
                </RequireAuth>
              }
            />
            <Route
              path="/Listar/Set/:id"
              element={
                <RequireAuth>
                  <UpdateSons />
                </RequireAuth>
              }
            />
            <Route
              path="/Listar/SetFile/:id"
              element={
                <RequireAuth>
                  <UpdateFileSons />
                </RequireAuth>
              }
            />

            <Route
              path="/Programas"
              element={
                <RequireAuth>
                  <Programs />
                </RequireAuth>
              }
            />
            <Route
              path="/Programas/Listar"
              element={
                <RequireAuth>
                  <FilterByprograms />
                </RequireAuth>
              }
            />
            <Route
              path="/Programas/Listar/:id"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route
              path="/Programas/AddById"
              element={
                <RequireAuth>
                  <AddSonById />
                </RequireAuth>
              }
            />
            <Route
              path="/Programas/age"
              element={
                <RequireAuth>
                  <FilterAge />
                </RequireAuth>
              }
            />
            <Route
              path="/Programas/age/:id"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route
              path="/Programas/school"
              element={
                <RequireAuth>
                  <FilterSchool />
                </RequireAuth>
              }
            />
            <Route
              path="/Programas/school/:id"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route
              path="/Escuelas"
              element={
                <RequireAuth>
                  <School />
                </RequireAuth>
              }
            />
            <Route
              path="/Escuelas/age"
              element={
                <RequireAuth>
                  <FilterByAge />
                </RequireAuth>
              }
            />
            <Route
              path="/Escuelas/scholarship"
              element={
                <RequireAuth>
                  <FilterByScholarship />
                </RequireAuth>
              }
            />
            <Route
              path="/Escuelas/Listar"
              element={
                <RequireAuth>
                  <ListBySchool />
                </RequireAuth>
              }
            />
            <Route
              path="/Escuelas/Listar/:id"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route
              path="/Escuelas/Agregar"
              element={
                <RequireAuth>
                  <AddById />
                </RequireAuth>
              }
            />
            <Route
              path="/Comite"
              element={
                <RequireAuth>
                  <Comitte />
                </RequireAuth>
              }
            />
            <Route
              path="/Comite/Voluntario"
              element={
                <RequireAuth>
                  <VolunteerCommitte />
                </RequireAuth>
              }
            />
            <Route
              path="/Comite/Conyuge"
              element={
                <RequireAuth>
                  <CoupleCommitte />
                </RequireAuth>
              }
            />
            <Route
              path="/Commite/Crear"
              element={
                <RequireAuth>
                  <CreateCommitte />
                </RequireAuth>
              }
            />
            <Route
              path="/Spouse/Create"
              element={
                <RequireAuth>
                  <CreateSpouse />
                </RequireAuth>
              }
            />
            <Route
              path="/Volunteer/Create"
              element={
                <RequireAuth>
                  <CreateVolunteer />
                </RequireAuth>
              }
            />
            <Route
              path="/Miembros/InactivosPlanta"
              element={
                <RequireAuth>
                  <InactivityBusiness />
                </RequireAuth>
              }
            />
            <Route
              path="/Miembros/Inactivos"
              element={
                <RequireAuth>
                  <Inactivity />
                </RequireAuth>
              }
            />
            <Route
              path="/Miembros/ProveedorPlanta"
              element={
                <RequireAuth>
                  <Supplier />
                </RequireAuth>
              }
            />
            <Route
              path="/Miembros/ProveedoresID"
              element={
                <RequireAuth>
                  <SupplierByID />
                </RequireAuth>
              }
            />
            <Route
              path="/Miembros/Voluntarios"
              element={
                <RequireAuth>
                  <Volunteers />
                </RequireAuth>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Fragment>
      </BrowserRouter>
    </AuthProvider>
  );
}
