import React from "react";
import { Link } from "react-router-dom";
import "../../../../Stylesheet/Filters.css";

export default function FilterPrograms() {
  return (
    <div
      className="btn-group group-filter"
      role="group"
      aria-label="Button group with nested dropdown"
    >
      <div className="btn-group" role="group">
        <button
          type="button"
          className="btn btn-outline-warning dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Asignar A Programa
        </button>
        <ul className="dropdown-menu">
          <li>
            <Link className="dropdown-item" to={"/Programas/age"}>
              Por Edad
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to={"/Programas/school"}>
              Por Escuela
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to={"/Programas/AddById"}>
              Por Identificacion
            </Link>
          </li>
        </ul>
      </div>
      <div className="btn-group" role="group">
        <button
          type="button"
          className="btn btn-outline-warning dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Listar Por Programa
        </button>
        <ul className="dropdown-menu">
          <li>
            <Link className="dropdown-item" to={"/Programas/Listar"}>Programa</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
