import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import NavBar from "../../../Layouts/NavBar";
import axios from "axios";
import FilterMembers from "./FilterMembers";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../../Services/AuthContext";

export default function MemberBusiness() {
  const [employee, setEmployee] = useState([]);
  const [business, setBusiness] = useState([]);

  const ref = useRef();
  const auth = useAuth()

  const handlePrint = useReactToPrint({
    content: () => ref.current,
    documentTitle: "Reporte Por edad",
  });

  const showData = async (e) => {
    e.preventDefault();

    const response = await toast.promise(
      axios.post(
        `/api/employee/business`,
        {
          business: business,
        },
        {
          headers: {
            authorization: `Bearer ${auth.Ldata}`
          },
          withCredentials: true,
        }
      ),
      {
        pending: 'Filtrando...',
        success: 'Filtrado Exitoso',
        error: 'Error'
      }
    )
    console.log(response.status)
    setEmployee(response.data)
  };

  useEffect(() => {
    showData();
  }, []);
  return (
    <>
      <header>
        <NavBar />
        <FilterMembers />
      </header>

      <div>
        <div className="container-fluid">
          <form className="row g-3" onSubmit={showData}>
            <div className="col-auto">
              <select
                className="form-select"
                aria-label="Business"
                onChange={(e) => setBusiness(e.target.value)}
              >
                <option selected>Planta</option>
                <option value="1">Planta de Cienaga</option>
                <option value="2">Planta de Buenaventura</option>
              </select>
            </div>
            <div className="col-auto">
              <button type="submit" className="btn btn-primary mb-3">
                Search Data
              </button>
            </div>
          </form>
        </div>
        <div className="data-container justify-content-center align-items-center">
          <spam>Total Filtrado: {employee?.length > 0 ? employee?.length : 0}</spam>
          <button onClick={handlePrint} className="btn btn-primary mb-3">
            Descargar Reporte
          </button>
        </div>
        <div className="table-responsive" ref={ref}>
          <table className="table table-striped table-hover mt-5 shadow-lg">
            <thead>
              <tr className="bg-grupo text-black ">
                <th>id</th>
                <th>Primer Nombre</th>
                <th>Segundo Nombre</th>
                <th>Primer Apellido</th>
                <th>Segundo Apellido</th>
              </tr>
            </thead>
            <tbody>
              {Object.values(employee).map((user) => (
                <tr key={user.cc}>
                  <td>{user.cc}</td>
                  <td>{user.firstname}</td>
                  <td>{user.secondname}</td>
                  <td>{user.first_lastname}</td>
                  <td>{user.second_lastname}</td>
                  <td>
                    <Link
                      to={`/Miembros/business/${user.cc}`}
                      className="btn btn-outline-primary text-center"
                    >
                      Mas Informacion
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
