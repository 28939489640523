import { useState, useEffect } from "react";
import NavBar from "../../../Layouts/NavBar";
import FilterListSchool from "./FilterListsSchool";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import axios from "axios";
import { useAuth } from "../../../Services/AuthContext";

export default function AddById() {
  const [idson, setIdSon] = useState(0);
  const [school, setSchool] = useState("");

  const auth = useAuth()

  const showData = async (e) => {
    e.preventDefault();

    const response = await toast.promise(
      axios.post(
        `/api/school/add`,
        {
          school: school,
          id_son_dougther: idson,
        },
        {
          headers: {
            authorization: `Bearer ${auth.Ldata}`,
          },
          withCredentials: true,
        }
      ),
      {
        pending: "Agregando",
        success: "Agregado A Escuela",
        error: "Error",
      }
    );
    console.log(response.status)
  };

  useEffect(()=>{
    showData()
  },[])
  return (
    <>
      <header>
        <NavBar />
        <FilterListSchool />
      </header>
      <div>
        <form className="row g-3" onSubmit={showData}>
          <div className="col-auto">
            <div className="col-auto input-field">
              <label for="inputId" className="visually-hidden">
                Edad
              </label>
              <input
                type="number"
                className="form-control"
                id="inputId"
                placeholder="Identificacion"
                onChange={(e) => setIdSon(e.target.value)}
              />
            </div>
          </div>
          <div className="col-auto">
            <select
              className="form-select"
              aria-label="Operator"
              onChange={(e) => setSchool(e.target.value)}
            >
              <option selected>Seleccione el Escuela</option>
              <option value="1">Natacion</option>
              <option value="2">Futbol</option>
              <option value="3">Baseball</option>
              <option value="4">Atletismo</option>
            </select>
          </div>
          <div className="col-auto">
            <button type="submit" className="btn btn-primary mb-3">
              Search Data
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
