import React,{useState,useEffect} from "react";
import NavBar from "../../../Layouts/NavBar";
import ListFiltersComitte from "./ListFiltersComitte";
import {toast} from "react-toastify"
import axios from "axios"
import {useAuth} from "../../../Services/AuthContext"

export default function CoupleCommitte(){

    const [Spouse,setSpouse] = useState(0)
    const [committe, setCommitte] = useState('')
    const [committes, setCommites] = useState([])

    const auth = useAuth()
    const showData = async(e) =>{
        e.preventDefault()

        const response = await toast.promise(
          axios.post(`/api/committe/AddVolunteer`,{
            "spouse":Spouse,"committe":committe
          },{headers:{
            authorization: `Brearer ${auth.Ldata}`
          }, withCredentials: true}),{
            pending: 'Agregando',
            success: 'Agregado',
            error: 'Error'
          })
        console.log(response)
    }

    const ShowCommittes = async() =>{
      const response = await axios.get(`/api/committe/getcommitte`,{
            headers: {
              authorization: `Bearer ${auth.Ldata}`
            }, withCredentials: true
          })        
      console.log(response)
      setCommites(response.data)
    }
    useEffect(()=>{
        showData()
        ShowCommittes()
    },[])

    return(
        <>
      <header>
        <NavBar />
        <ListFiltersComitte />
      </header>
      <div>
        <div className="container-fluid">
          <form className="row g-3" onSubmit={showData}>
          <div className="col-auto input-field">
                        <label for="inputComitte" className="visually-hidden">Identificacion</label>
                        <input type="text" className="form-control" id="inputCommitte" placeholder="Identificacion" onChange={(e)=> setSpouse(e.target.value)}/>
                    </div>
            <div className="col-auto">
              <select
                className="form-select"
                aria-label="School"
                onChange={(e) => setCommitte(e.target.value)}
              >
                <option selected>Comités</option>
                {Object.values(committes).map((comite)=>(
                  <option value={comite.committe_id}>{comite.name_committe}</option>
                ))}
              </select>
            </div>
            <div className="col-auto">
              <button type="submit" className="btn btn-primary mb-3">
                Search Data
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
    )
}