import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import NavBar from "../../../Layouts/NavBar";
import FilterPrograms from "./FiltersPrograms";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../../Services/AuthContext";

export default function FilterByprograms() {
  
    const [employee, setEmployee] = useState([])
    const [program, setProgram] = useState([]);

  const auth = useAuth()
  const showData = async (e) => {
    e.preventDefault();

    const response = await toast.promise(
      axios.post(`/api/programs/getPrograms`,{
        program: program
      },{
        headers: {
          authorization: `Bearer ${auth.Ldata}`
        }, withCredentials: true
      }),{
        pending: 'Filtrando...',
        success: 'Filtrado Con Exito',
        error: 'Error'
      }
    )
    setEmployee(response.data)
  };
  return (
    <>
    <header>
        <NavBar />
        <FilterPrograms />
    </header>
      <div>
        <form className="row g-3" onSubmit={showData}>
          <div className="col-auto">
            <select
              className="form-select"
              aria-label="Operator"
              onChange={(e) => setProgram(e.target.value)}
            >
              <option selected>Seleccione Programa</option>
              <option value="1">Pescando Talentos</option>
              <option value="2">Viste la pesca</option>
              <option value="3">Olas del Futuro</option>
              <option value="4">Tiburon Mama</option>
              <option value="5">A mar Navidad</option>
            </select>
          </div>
          <div className="col-auto">
            <button type="submit" className="btn btn-primary mb-3">
              Search Data
            </button>
          </div>
        </form>
        <div className="table-responsive">
        <table className="table table-striped table-hover mt-5 shadow-lg"> 
                <thead>
                    <tr className="bg-grupo text-black ">
                        <th>id</th>
                        <th>Primer Nombre</th>
                        <th>Segundo Nombre</th>
                        <th>Primer Apellido</th>
                        <th>Segundo Apellido</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.values(employee).map((emploees) => (
                        <tr key={emploees.id_son_dougther}>
                            <td>{emploees.id_son_dougther}</td>
                            <td>{emploees.firstname}</td>
                            <td>{emploees.secondname}</td>
                            <td>{emploees.first_lastname}</td>
                            <td>{emploees.second_lastname}</td>
                            <td><Link to={`/Programas/Listar/${emploees.id_son_dougther}`} className="btn btn-outline-primary text-center">Mas Informacion</Link></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
      </div>
    </>
  );
}
