import React from "react";
import NavBar from "../Layouts/NavBar";
import SonsList from "../Views/PersonView/SonsList";
import Filters from "./FilterViews/Beneficiaries/Filters"

export default function Beneficiarios() {
  return (
    <>
    <header>
    <NavBar />
    </header>

      <div>
        <Filters />
      </div>
    </>
  );
}
