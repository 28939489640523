import React from "react";
import { Link } from "react-router-dom";
import "../../../../Stylesheet/Filters.css";

export default function FilterListSchool() {
  return (
    <div
      className="btn-group group-filter"
      role="group"
      aria-label="Button group with nested dropdown"
    >
      <div className="btn-group" role="group">
        <button
          type="button"
          className="btn btn-outline-warning dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Asignar a Escuela
        </button>
        <ul className="dropdown-menu">
          <li>
            <Link className="dropdown-item" to={"/Escuelas/age"}>
              Edad
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to={"/Escuelas/scholarship"}>
              Escolaridad
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to={"/Escuelas/Agregar"}>
              Identificacion
            </Link>
          </li>
        </ul>
      </div>
      <div className="btn-group" role="group">
        <button  type="button"
          className="btn btn-outline-warning dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false">
            Listar Por Escuela
        </button>
        <ul className="dropdown-menu">
          <li>
            <Link className="dropdown-item" to={"/Escuelas/Listar"}>
              Escuela
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
