import React from "react";
import NavBar from "../../../Layouts/NavBar";
import ListFiltersComitte from "./ListFiltersComitte";

export default function Comitte() {
  return (
    <>
      <header>
        <NavBar />
        <ListFiltersComitte />
      </header>
    </>
  );
}
