import { React } from "react";

import "../../Stylesheet/NavBar.css";
import { Link } from "react-router-dom";

const NavBar = () => {
  return (
    <>
      <div className="navbar navbar-expand-lg">
        <div className="container-fluid container container-data fw-semibold">
          <Link to={"/"} className="navbar-brand fw-semibold">
            <img
              src={require("../../images/SLOGO.png")}
              className="d-inline-block align-text-top"
              alt="Logo"
              width="50"
              height="34"
            />
            Fundacion Mares
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#nav"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse fw-semibold" id="nav">
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#!"
                  data-bs-toggle="dropdown"
                >
                  Miembros
                </a>
                <ul className="dropdown-menu dropdown-menu">
                  <li>
                    <Link to={"/Miembros"} className="dropdown-item">
                      Filtros
                    </Link>
                    <Link to={"/Crear/Miembro"} className="dropdown-item">
                      Agregar Miembro
                    </Link>
                    <Link to={"/Spouse/Create"} className="dropdown-item">
                      Agregar Conyuge
                    </Link>
                    <Link to={"/Volunteer/Create"} className="dropdown-item">
                      Agregar Voluntario
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#!"
                  data-bs-toggle="dropdown"
                >
                  Beneficiarios
                </a>
                <ul className="dropdown-menu dropdown-menu">
                  <li>
                    <Link to={"/Listar"} className="dropdown-item">
                      Filtros
                    </Link>
                    <Link to={"/Crear/Beneficiario"} className="dropdown-item">
                      Agregar
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={"/Programas"}>
                  Programas
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={"/Escuelas"}>
                  Escuelas
                </Link>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#!"
                  data-bs-toggle="dropdown"
                >
                  {" "}
                  Comite
                </a>
                <ul className="dropdown-menu dropdown-menu">
                  <li>
                    <Link className="nav-link" to={"/Commite/Crear"}>
                      Crear Comite
                    </Link>
                    <Link className="nav-link" to={"/Comite"}>
                      Manejar Comite
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link className="nav-link">Cerrar Sesion</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBar;
