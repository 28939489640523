import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Link } from "react-router-dom";
import NavBar from "../../../Layouts/NavBar";
import FilterMembers from "./FilterMembers";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../../Services/AuthContext";

export default function MemberCouple() {
  const [couple, setCouple] = useState([]);

  const auth = useAuth()
  const ref = useRef();
  
  const handlePrint = useReactToPrint({
    content: () => ref.current,
    documentTitle: "Reporte Por Conyuge",
  });
  const showData = async (e) => {

    const response = await toast.promise(

      axios.get(
        `api/spouse/GetCouple`,
        {
          headers: {
            authorization: `Bearer ${auth.Ldata}`,
          },
          withCredentials: true,
        }
      ),{
        pending: 'Filtrando...',
        success: 'Filtrado Exitoso',
        error: 'Error'
      }
    )
    console.log(response.data)
    setCouple(response.data);
  };

  useEffect(() => {
    showData();
  }, []);
  return (
    <>
      <header>
        <NavBar />
        <FilterMembers />
      </header>
      
      <div>
        <div className="data-container justify-content-center align-items-center">
          <spam>
            Total Filtrado: {couple?.length > 0 ? couple?.length : 0}
          </spam>
          <button onClick={handlePrint} className="btn btn-primary mb-3">
            Descargar Reporte
          </button>
        </div>
        <div className="table-responsive" ref={ref}>
          <table className="table table-striped table-hover mt-5 shadow-lg">
            <thead>
              <tr className="bg-grupo text-black ">
                <th>id</th>
                <th>Primer Nombre</th>
                <th>Segundo Nombre</th>
                <th>Primer Apellido</th>
                <th>Segundo Apellido</th>
              </tr>
            </thead>
            <tbody>
              {Object.values(couple).map((Couple) => (
                <tr key={Couple.cc}>
                  <td>{Couple.cc}</td>
                  <td>{Couple.firstname}</td>
                  <td>{Couple.secondname}</td>
                  <td>{Couple.first_lastname}</td>
                  <td>{Couple.second_lastname}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
