import axios from "axios";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import NavBar from "../NavBar";
import { useAuth } from "../../Services/AuthContext";


export default function CreateCommitte() {
  const [name, setName] = useState("");
  const [school, setSchool] = useState(0);

  const auth = useAuth()

  const showData = async(e) =>{
    e.preventDefault()
    const response = await toast.promise(
        axios.post(`/api/committe/create`,{
            name: name, school: school
        },{
            headers: {
                authorization: `Bearer ${auth.Ldata}`
            },withCredentials: true
        }),
        {
            pending: "Creando Comite",
            success: "Creado",
            error: "Error"
        }
    )
    console.log(response)
  }

  useEffect(()=>{
    showData()
  },[])
  return (
    <>
      <header>
        <NavBar />
      </header>
      <body>
        <div className="container">
          <form
            className="row mt-3 g-3 needs-validation"
            onSubmit={showData}
            noValidate
          >
            <div className="col-6">
              <label for="Committe" className="form-label">
                Nombre de Committe
              </label>
              <input
                type="text"
                className="form-control"
                id="Committe"
                onChange={(e) => setName(e.target.value)}
                required
              />
              <div className="valid-feedback">ok</div>
              <div className="invalid-feedback">Obligatorio</div>
            </div>
            <div className="col-6">
              <label for="inputName" className="form-label">
                Escuela a Asignar
              </label>
              <select
                className="form-select"
                id="inputName"
                onChange={(e) => setSchool(e.target.value)}
                required
              >
                <option selected>Seleccione la Escuela a Asignar</option>
                <option value="1">Natacion</option>
                <option value="2">Futbol</option>
                <option value="3">Baseball</option>
                <option value="4">Atletismo</option>
              </select>
            </div>
            <button type="submit" className="btn btn-primary">
              Enviar
            </button>
          </form>
        </div>
      </body>
    </>
  );
}
