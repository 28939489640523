import React from "react";
import "../../../../Stylesheet/Filters.css";
import { Link } from "react-router-dom";

export default function Filter() {
  return (
    <div
      className="btn-group group-filter"
      role="group"
      aria-label="Button group with nested dropdown"
    >
      {/* Filtros Por Genero */}
      <div className="btn-group" role="group">
        <button
          type="button"
          className="btn btn-outline-warning dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Genero
        </button>
        <ul className="dropdown-menu">
          <li>
            <Link className="dropdown-item" to={"/Listar/gender"}>
              Genero
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to={"/Listar/genderAge"}>
              Genero Y Edad
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to={"/Listar/businessGender"}>
              Genero Y Planta
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to={"/Listar/businessGenderAge"}>
              Genero, Planta Y Edad
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to={"/Listar/ScholarshipGender"}>
              Escolaridad Y Genero
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to={"/Listar/ScholarshipGenderBusiness"}>
              Escolaridad, Genero Y Planta
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to={"/Listar/SpecieGender"}>
              Especie Y Genero
            </Link>
          </li>
        </ul>
      </div>
      {/* Edad */}
      <div className="btn-group" role="group">
        <button
          type="button"
          className="btn btn-outline-warning dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Edad
        </button>
        <ul className="dropdown-menu">
          <li>
            <Link className="dropdown-item" to={"/Listar/edad"}>
              Edad
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to={"/Listar/Specieage"}>
              Especie Y Edad
            </Link>
          </li>
          <Link className="dropdown-item" to={"/Listar/Range"}>
            Rango de edad
          </Link>
        </ul>
      </div>

      {/* Escolaridad */}

      <div className="btn-group" role="group">
        <button
          type="button"
          className="btn btn-outline-warning dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Escolaridad
        </button>
        <ul className="dropdown-menu">
          <li>
            <Link className="dropdown-item" to={"/Listar/Scholarship"}>
              Escolaridad
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to={"/Listar/scholarshipYear"}>
              Escolaridad y Año
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to={"/Listar/ScholarshipGender"}>
              Escolaridad Y Genero
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to={"/Listar/ScholarshipGenderBusiness"}>
              Planta,Escolaridad Y Genero
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to={"/Listar/businessGenderAge"}>
              Planta, Genero y Edad
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to={""}>
            
            </Link>
          </li>
        </ul>
      </div>

      {/* Planta */}

      <div className="btn-group" role="group">
        <button
          type="button"
          className="btn btn-outline-warning dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Planta
        </button>
        <ul className="dropdown-menu">
          <li>
            <Link className="dropdown-item" to={"/Listar/business"}>
              Planta
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to={"/Listar/businessGender"}>
              Planta Y Genero
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to={"/Listar/ScholarshipGenderBusiness"}>
              Planta, Escolaridad Y Genero
            </Link>
          </li>
        </ul>
      </div>

      {/* Programa */}

      <div className="btn-group" role="group">
        <button
          type="button"
          className="btn btn-outline-warning dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Programa
        </button>
        <ul className="dropdown-menu">
          <li>
            <Link className="dropdown-item" to={"/Listar/programa"}>
              Programa
            </Link>
          </li>
        </ul>
      </div>

      {/* Otros */}
      <div className="btn-group" role="group">
        <button
          type="button"
          className="btn btn-outline-warning dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Otros
        </button>
        <ul className="dropdown-menu">
          <li>
            <Link className="dropdown-item" to={"/Listar/all"}>
              Sin Filtros
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to={"/Listar/AllFilter"}>
              Todos Los Filtros
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
