import NavBar from "../../../Layouts/NavBar";
import axios from "axios";
import FilterMembers from "./FilterMembers";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useReactToPrint } from "react-to-print";
import { useState,useRef,useEffect } from "react";
import { useAuth } from "../../../Services/AuthContext";

export default function SupplierByID() {
  const [employee, setEmployee] = useState([]);
  const [supplier, setSupplier] = useState('')
  const [suppliers, setSuppliers] = useState([])

  const auth = useAuth()
  const ref = useRef();

  const handlePrint = useReactToPrint({
    content: () => ref.current,
    documentTitle: "Reporte Por edad",
  });

  const showData = async (e) => {
    e.preventDefault();

    const response = await axios.post(
        `/api/employee/getSupplierBussiness`,
        {
          "supplier": supplier
        },
        {
          headers: {
            authorization: `Bearer ${auth.Ldata}`,
          },
          withCredentials: true,
        }
      )
    console.log(response)
    console.log(response.status);
    setEmployee(response.data);
  };
  const getSuppliers = async () => {

    const response = await axios.get(
        `/api/employee/getsupplier`,
        {
          headers: {
            authorization: `Bearer ${auth.Ldata}`,
          },
          withCredentials: true,
        }
      )
    console.log(response)
    console.log(response.status);
    setSuppliers(response.data);
  };

  useEffect(() => {
    showData();
    getSuppliers()
  }, []);
  return (
    <>
      <header>
        <NavBar />
        <FilterMembers />
      </header>
      <div>
        <div className="container-fluid">
          <form className="row g-3" onSubmit={showData}>
            <div className="col-auto">
              <select
                className="form-select"
                aria-label="Business"
                onChange={(e) => setSupplier(e.target.value)}
              >
                <option selected>Proveedores</option>
                {Object.values(suppliers).map((suppliers)=>(
                    <option value={suppliers.id}>{suppliers.firstname+ " " + suppliers.First_lastname}</option>
                ))}
              </select>
            </div>
            <div className="col-auto">
              <button type="submit" className="btn btn-primary mb-3">
                Search Data
              </button>
            </div>
          </form>
        </div>
        <div className="data-container justify-content-center align-items-center">
          <spam>
            Total Filtrado: {employee?.length > 0 ? employee?.length : 0}
          </spam>
          <button onClick={handlePrint} className="btn btn-primary mb-3">
            Descargar Reporte
          </button>
        </div>
        <div className="table-responsive" ref={ref}>
          <table className="table table-striped table-hover mt-5 shadow-lg">
            <thead>
              <tr className="bg-grupo text-black ">
                <th>id</th>
                <th>Primer Nombre</th>
                <th>Segundo Nombre</th>
                <th>Primer Apellido</th>
                <th>Segundo Apellido</th>
              </tr>
            </thead>
            <tbody>
              {Object.values(employee).map((user) => (
                <tr key={user.cc}>
                  <td>{user.cc}</td>
                  <td>{user.firstname}</td>
                  <td>{user.secondname}</td>
                  <td>{user.first_lastname}</td>
                  <td>{user.second_lastname}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}