import axios from "axios";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useAuth } from "../../Services/AuthContext";
import NavBar from "../NavBar";

export default function CreateVolunteer() {
  const [id, setId] = useState(0);
  const [firstname, setFirstname] = useState("");
  const [secondname, setSecondname] = useState("");
  const [first_lastname, setFirst_lastname] = useState("");
  const [second_lastname, setSecond_lastname] = useState("");
  const [cellphone, setCellphone] = useState(0);
  const [email, setEmail] = useState("");
  const [activity, setActivity] = useState("");

  const auth = useAuth()

  const showData = async (e) => {
    e.preventDefault();
    const response = await toast.promise(
      axios.post(
        `/api/volunteer/create`,
        {
          id: id,
          firstname: firstname,
          secondname: secondname,
          first_lastname: first_lastname,
          second_lastname: second_lastname,
          cellphone: cellphone,
          email: email,
          activity: activity,
        },
        {
          headers: {
            authorization: `Bearer ${auth.Ldata}`,
          },
          withCredentials: true,
        }
      ),
      {
        pending: "Creando Comite",
        success: "Creado",
        error: "Error",
      }
    );
    console.log(response);
  };

  useEffect(() => {
    showData();
  }, []);
  return (
    <>
      <header>
        <NavBar />
      </header>
      <body>
        <div className="container">
          <form
            className="row mt-3 g-3 needs-validation"
            onSubmit={showData}
            noValidate
          >
            <div className="col-6">
              <label for="Id" className="form-label">
                Identificacion
              </label>
              <input
                type="number"
                className="form-control"
                id="Id"
                onChange={(e) => setId(e.target.value)}
                required
              />
              <div className="valid-feedback">ok</div>
              <div className="invalid-feedback">Obligatorio</div>
            </div>
            <div className="col-6">
              <label for="firstname" className="form-label">
                Primer Nombre
              </label>
              <input
                type="text"
                className="form-control"
                id="firstname"
                onChange={(e) => setFirstname(e.target.value)}
                required
              />
              <div className="valid-feedback">ok</div>
              <div className="invalid-feedback">Obligatorio</div>
            </div>
            <div className="col-6">
              <label for="secondname" className="form-label">
                Segundo Nombre
              </label>
              <input
                type="text"
                className="form-control"
                id="secondname"
                onChange={(e) => setSecondname(e.target.value)}
                required
              />
              <div className="valid-feedback">ok</div>
              <div className="invalid-feedback">Obligatorio</div>
            </div>
            <div className="col-6">
              <label for="first_lastname" className="form-label">
                Primer Apellido
              </label>
              <input
                type="text"
                className="form-control"
                id="first_lastname"
                onChange={(e) => setFirst_lastname(e.target.value)}
                required
              />
              <div className="valid-feedback">ok</div>
              <div className="invalid-feedback">Obligatorio</div>
            </div>
            <div className="col-6">
              <label for="second_lastname" className="form-label">
                Segundo Apellido
              </label>
              <input
                type="text"
                className="form-control"
                id="second_lastname"
                onChange={(e) => setSecond_lastname(e.target.value)}
                required
              />
              <div className="valid-feedback">ok</div>
              <div className="invalid-feedback">Obligatorio</div>
            </div>
            <div className="col-6">
              <label for="cellphone" className="form-label">
                Celular
              </label>
              <input
                type="number"
                className="form-control"
                id="cellphone"
                onChange={(e) => setCellphone(e.target.value)}
                required
              />
              <div className="valid-feedback">ok</div>
              <div className="invalid-feedback">Obligatorio</div>
            </div>
            <div className="col-6">
              <label for="email" className="form-label">
                Correo
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <div className="valid-feedback">ok</div>
              <div className="invalid-feedback">Obligatorio</div>
            </div>
            <div className="col-6">
              <label for="activity" className="form-label">
                Actividad a Realizar
              </label>
              <input
                type="text"
                className="form-control"
                id="activity"
                onChange={(e) => setActivity(e.target.value)}
                required
              />
              <div className="valid-feedback">ok</div>
              <div className="invalid-feedback">Obligatorio</div>
            </div>
            <button type="submit" className="btn btn-primary">
              Enviar
            </button>
          </form>
        </div>
      </body>
    </>
  );
}
