import React from "react";
import { Link } from "react-router-dom";
import "../../../../Stylesheet/Filters.css";

export default function FilterMembers() {
  return (
    <div
      className="btn-group group-filter"
      role="group"
      aria-label="Button Group"
    >
      {/* Genero */}
      <div className="btn-group" role="group">
        <button
          type="button"
          className="btn btn-outline-warning dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Genero
        </button>
        <ul className="dropdown-menu">
          <li>
            <Link className="dropdown-item" to={"/Miembros/gender"}>
              Genero
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to={"/Miembros/businessGender"}>
              Planta Y Genero
            </Link>
          </li>
        </ul>
      </div>
      <div className="btn-group" role="group">
        <button
          type="button"
          className="btn btn-outline-warning dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Planta
        </button>
        <ul className="dropdown-menu">
          <li>
            <Link className="dropdown-item" to={"/Miembros/business"}>
              Planta
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to={"/Miembros/businessGender"}>
              Planta Y Genero
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to={"/Miembros/SpeciesBusiness"}>
              Planta Y Especie
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to={"/Miembros/PositionBusiness"}>
              Planta Y Cargo
            </Link>
          </li>
        </ul>
      </div>
      <div className="btn-group" role="group">
        <button
          type="button"
          className="btn btn-outline-warning dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Especie
        </button>
        <ul className="dropdown-menu">
          <li>
            <Link className="dropdown-item" to={"/Miembros/Species"}>
              Especie
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to={"/Miembros/SpeciesBusiness"}>
              Especie Y Planta
            </Link>
          </li>
        </ul>
      </div>
      <div className="btn-group" role="group">
        <button
          type="button"
          className="btn btn-outline-warning dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Cargo
        </button>
        <ul className="dropdown-menu">
          <li>
            <Link className="dropdown-item" to={"/Miembros/Position"}>
              Cargo
            </Link>
          </li>
        </ul>
      </div>
      <div className="btn-group" role="group">
        <button
          type="button"
          className="btn btn-outline-warning dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Otros
        </button>
        <ul className="dropdown-menu">
          <li>
            <Link className="dropdown-item" to={"/Miembros/InactivosPlanta"}>
              Inactivos y Planta
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to={"/Miembros/Inactivos"}>
              Inactivos
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to={"/Miembros/ProveedorPlanta"}>
              Proveedores y Planta
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to={"/Miembros/ProveedoresID"}>
              Proveedores
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to={"/Miembros/Voluntarios"}>
              Voluntarios
            </Link>
          </li>
          <li>
            <Link className="dropdown-item" to={"/Miembros/couple"}>
              Conyuge
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
