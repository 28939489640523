import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import NavBar from "./NavBar";
import axios from "axios";
import { useAuth } from "../Services/AuthContext";

export default function ProfileEmployee() {
  const [employee, setEmployee] = useState([]);
  const [sons, setSons] = useState([]);

  let { id } = useParams();
  const auth = useAuth();
  const ShowData = async () => {
    const [response1, response2] = await Promise.all([
      axios.get(`/api/employee/get/${id}`, {
        headers: {
          authorization: `Bearer ${auth.Ldata}`,
        },
        withCredentials: true,
      }),
      axios.get(`/api/employee/getSons/${id}`, {
        headers: {
          authorization: `Bearer ${auth.Ldata}`,
        },
        withCredentials: true,
      }),
    ]);

    console.log(response1.data);
    console.log(response2.data);
    setEmployee(response1.data);
    setSons(response2.data);
  };

  useEffect(() => {
    ShowData();
  }, []);
  return (
    <>
      <header>
        <NavBar />
      </header>
      {Object.values(employee).map((Employee) => (
        <div className="row">
          <div className="col-lg-6 p-3">
            <div className="card mb-4 h-100">
              <div className="card-body">
                <div className="row">
                  <h5 className="card-tittle">Informacion Personal</h5>
                  <hr />
                  <div className="col-sm-3">
                    <p className="mb-0">Nombre Completo</p>
                  </div>
                  <div className="col-sm-9">
                    <p className="text-muted mb-0">
                      {Employee.firstname +
                        " " +
                        (Employee.secondname ? Employee.secondname : "") +
                        " " +
                        Employee.first_lastname +
                        " " +
                        Employee.second_lastname}
                    </p>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <p className="mb-0">Contacto</p>
                  </div>
                  <div className="col-sm-9">
                    <p className="text-muted mb-0">{Employee.cellphone}</p>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <p className="mb-0">Genero</p>
                  </div>
                  <div className="col-sm-9">
                    <p className="text-muted mb-0">{Employee.gender}</p>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <p className="mb-0">Fecha de Nacimiento</p>
                  </div>
                  <div className="col-sm-9">
                    <p className="text-muted mb-0">{Employee.date_birth}</p>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <p className="mb-0">Conyuge</p>
                  </div>
                  <div className="col-sm-9">
                    <p className="text-muted mb-0">
                      {(Employee.cfirstname ? Employee.cfirstname : "") +
                        " " +
                        (Employee.csecondname ? Employee.csecondname : "") +
                        " " +
                        (Employee.cfirst_lastname
                          ? Employee.cfirst_lastname
                          : "") +
                        " " +
                        (Employee.csecond_lastname
                          ? Employee.csecond_lastname
                          : "")}
                    </p>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <p className="mb-0">Opciones</p>
                  </div>
                  <div className="col-sm-9">
                    <Link
                      className="btn btn-outline-success p-2"
                      to={`/Miembros/CC/${id}`}
                    >
                      Cedula
                    </Link>
                    <Link
                      className="btn btn-outline-success p-2"
                      to={`/Miembros/Foto/${id}`}
                    >
                      Foto Familiar
                    </Link>
                    <Link
                      className="btn btn-outline-success p-2"
                      to={`/Miembros/Set/${id}`}
                    >
                      Editar
                    </Link>
                    <Link
                      className="btn btn-outline-success p-2"
                      to={`/Miembros/SetFiles/${id}`}
                    >
                      Editar Archivos
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 p-3">
            <div className="card mb-4 h-100">
              <div className="card-body">
                <div className="row">
                  <h5 className="card-tittle">Informacion Laboral</h5>
                  <hr />
                  <div className="col-sm-3">
                    <p className="mb-0">Cargo</p>
                  </div>
                  <div className="col-sm-9">
                    <p className="text-muted mb-0">{Employee.rolename}</p>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <p className="mb-0">EPS</p>
                  </div>
                  <div className="col-sm-9">
                    <p className="text-muted mb-0">{Employee.EPS}</p>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <p className="mb-0">Cesantias</p>
                  </div>
                  <div className="col-sm-9">
                    <p className="text-muted mb-0">{Employee.cesantias}</p>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <p className="mb-0">Especie</p>
                  </div>
                  <div className="col-sm-9">
                    <p className="text-muted mb-0">{Employee.species_name}</p>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <p className="mb-0">Estado</p>
                  </div>
                  <div className="col-sm-9">
                    <p className="text-muted mb-0">{Employee.status}</p>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <p className="mb-0">Fecha de Ingreso</p>
                  </div>
                  <div className="col-sm-9">
                    <p className="text-muted mb-0">{Employee.date_admission}</p>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-sm-3">
                    <p className="mb-0">Opciones</p>
                  </div>
                  <div className="col-sm-9">
                    <p className="text-muted mb-0">
                      <Link
                        className="btn btn-outline-success"
                        to={`/Miembros/Certificado/${id}`}
                      >
                        Carnet De Pesca
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      <div className="table-responsive">
        <table className="table table-striped table-hover mt-5 shadow-lg">
          <thead>
            <tr className="bg-grupo text-black ">
              <th>Identificacion</th>
              <th>Nombre Completo</th>
            </tr>
          </thead>
          <tbody>
            {Object.values(sons).map((son) => (
              <tr key={son.id_son_dougther}>
                <td>{son.id_son_dougther}</td>
                <td>
                  {son.firstname +
                    " " +
                    son.secondname +
                    " " +
                    son.first_lastname +
                    " " +
                    son.second_lastname}
                </td>
                <td>
                  <Link
                    to={`/Listar/Scholarship/${son.id_son_dougther}`}
                    className="btn btn-outline-primary text-center"
                  >
                    Mas Informacion
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
