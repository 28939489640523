import NavBar from "../../../Layouts/NavBar";
import axios from "axios";
import FilterMembers from "./FilterMembers";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useReactToPrint } from "react-to-print";
import { useState, useRef, useEffect } from "react";
import { useAuth } from "../../../Services/AuthContext";

export default function Volunteers() {
  const [employee, setEmployee] = useState([]);
  const [volunteer, setVolunteer] = useState("");
  const [Volunteers, setVolunteers] = useState([]);

  const auth = useAuth()
  const ref = useRef();

  const handlePrint = useReactToPrint({
    content: () => ref.current,
    documentTitle: "Reporte Por edad",
  });

  const showData = async () => {

    const response = await toast.promise(
      axios.get(
        `/api/employee/volunteers`,
        {
          headers: {
            authorization: `Bearer ${auth.Ldata}`,
          },
          withCredentials: true,
        }
      ),{
        pending: 'Filtrando...',
        success: 'Filtrado Con Exito',
        error: 'Error'
      }
    );
    console.log(response);
    setEmployee(response.data);
  };

  useEffect(() => {
    showData();
  }, []);
  return (
    <>
      <header>
        <NavBar />
        <FilterMembers />
      </header>
        <div className="data-container justify-content-center align-items-center">
          <spam>
            Total Filtrado: {employee?.length > 0 ? employee?.length : 0}
          </spam>
          <button onClick={handlePrint} className="btn btn-primary mb-3">
            Descargar Reporte
          </button>
        </div>
        <div className="table-responsive" ref={ref}>
          <table className="table table-striped table-hover mt-5 shadow-lg">
            <thead>
              <tr className="bg-grupo text-black ">
                <th>id</th>
                <th>Primer Nombre</th>
                <th>Segundo Nombre</th>
                <th>Primer Apellido</th>
                <th>Segundo Apellido</th>
                <th>Actividad</th>
              </tr>
            </thead>
            <tbody>
              {Object.values(employee).map((user) => (
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>{user.firstname}</td>
                  <td>{user.secondname}</td>
                  <td>{user.first_lastname}</td>
                  <td>{user.second_lastname}</td>
                  <td>{user.activity}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
    </>
  );
}