import React, { useEffect, useState } from "react";
import { Chart as ChartJs, ArcElement, Tooltip, Legend } from "chart.js/auto";
import { Doughnut } from "react-chartjs-2";

ChartJs.register(ArcElement, Tooltip, Legend);
export default function DoughNut({ masculino, femenino }) {
  var data = {
    labels: ["Masculino", "Femenino"],
    datasets: [
      {
        label: ["#Beneficiarios"],
        data: [masculino, femenino],
        backgroundColor: ["#26bde2", "rgb(252,195,11)"],
        hoverOffset: 4,
      },
    ],
  };
  var options = {
    borderWhidth: 10,
    boderRadius: 2,
    hoverBorderWidth: 0,
    plugins: {
      legend: {
        display: true,
      },
    },
  };
  return (
    <div>
      <Doughnut height={400} data={data} options={options}/>
    </div>
  );
}
