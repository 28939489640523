import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import "react-toastify/dist/ReactToastify.css";
import Filter from "./Filters";
import Cookie from "js-cookie";
import NavBar from "../../../Layouts/NavBar";
import "../../../../Stylesheet/Age.css";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuth } from "../../../Services/AuthContext";

export default function RangeAge() {
  const [Sons, setSons] = useState([]);
  const [minAge, setMinAge] = useState("");
  const [maxAge, setMaxAge] = useState("");

  const ref = useRef();
  const auth = useAuth()

  const handlePrint = useReactToPrint({
    content: () => ref.current,
    documentTitle: "Reporte Por Especie (Pesca) Y Edad",
  });
  const showData = async (e) => {
    e.preventDefault();

    try {
      const response = await toast.promise(
        axios.post(
          `/api/sons/age/range`,
          { minAge: minAge, maxAge: maxAge },
          {
            headers: {
              Authorization: `Bearer ${auth.Ldata}`,
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        )
      );
      console.log(response)
      setSons(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    showData();
  }, []);

  return (
    <div className="body">
      <header>
        <NavBar />
        <Filter />
      </header>

      <div className="container-fluid">
        <form className="row g-3" onSubmit={showData}>
          <div className="col-auto input-field">
            <label for="inputAge" className="visually-hidden">
              Edad
            </label>
            <input
              type="text"
              className="form-control"
              id="inputMinAge"
              placeholder="Minima Edad"
              onChange={(e) => setMinAge(e.target.value)}
            />
          </div>
          <div className="col-auto input-field">
            <label for="inputAge" className="visually-hidden">
              Edad
            </label>
            <input
              type="text"
              className="form-control"
              id="inputMaxAge"
              placeholder="Maxima edad"
              onChange={(e) => setMaxAge(e.target.value)}
            />
          </div>
          <div className="col-auto">
            <button type="submit" className="btn btn-primary mb-3">
              Search Data
            </button>
          </div>
        </form>
      </div>
      <div className="data-container justify-content-center align-items-center">
        <spam>Total Filtrado: {Sons?.length > 0 ? Sons?.length : 0}</spam>
        <button onClick={handlePrint} className="btn btn-primary mb-3">
          Descargar Reporte
        </button>
      </div>
      <div className="table-responsive" ref={ref}>
        <table className="table table-striped table-hover mt-5 shadow-lg">
          <thead>
            <tr className="bg-grupo text-black ">
              <th>Identificacion - Beneficiario</th>
              <th>Nombre - Beneficiario</th>
              <th>Identificacion - Miembro</th>
              <th>Nombre - Miembro</th>
              <th>Contacto</th>
            </tr>
          </thead>
          <tbody>
            {Object.values(Sons).map((son) => (
              <tr key={son.id_son_dougther}>
                <td>{son.id_son_dougther}</td>
                <td>
                  {son.firstname +
                    " " +
                    son.secondname +
                    " " +
                    son.first_lastname +
                    " " +
                    son.second_lastname}
                </td>
                <td>{son.CC}</td>
                <td>
                  {son.fathername +
                    " " +
                    son.fathersecondname +
                    " " +
                    son.fatherfirst_lastname +
                    " " +
                    son.fathersecond_lastname}
                </td>
                <td>{son.cellphone}</td>
                <td>
                  <Link
                    to={`/Listar/Scholarship/${son.id_son_dougther}`}
                    className="btn btn-outline-primary text-center"
                  >
                    Mas Informacion
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
