import { Chart as ChartJs, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJs.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

export default function ProgramsBar ({pescandotalentos,olasdelfuturo,vistelapesca,tiburonmama}){

    const data = {
        labels: ["Pescando Talentos","Olas Del Futuro","Viste la Pesca","Tiburon Mamá"],
        datasets: [
            {
                data: [pescandotalentos,olasdelfuturo,vistelapesca,tiburonmama],
                backgroundColor: '#26bde2',
                borderColor: '#26bde2',
                borderWidth: 1
            }
        ]
    }

    const options = {
        indexAxis: 'y',
        plugins: {
            display: false
        }
    }
    return(
        <div className="">
            <Bar data={data} options={options} />
        </div>
    )
}