import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../Services/AuthContext";
import axios from "axios";
import { toast } from "react-toastify";
import NavBar from "../NavBar";

export default function UpdateFileSons() {
  const auth = useAuth();
  const { id } = useParams();
  const [register, setRegister] = useState([]);
  const [typeRegister, setTypeRegister] = useState("");

  const convertToBase64 = (e) => {
    const file = e.target.files[0];
    setTypeRegister(file.type);

    const reader = new FileReader();
    reader.onloadend = () => {
      setRegister(reader.result.toString().substr(23));
    };
    reader.readAsDataURL(file);
  };

  const showDatabase = async (e) => {
    e.preventDefault();

    const response = await toast.promise(
      axios.put(
        `/api/sons/setSonsFile/${id}`,
        {
          register: register,
          typeRegister: typeRegister,
        },
        {
          headers: {
            authorization: `Bearer ${auth.Ldata}`,
          },
          withCredentials: true,
        }
      ),
      {
        pending: "Editando Beneficiario...",
        success: "Beneficiario Editado",
        error: "Beneficiario No Editado",
      }
    );

    console.log(response);
  };

  return (
    <>
      <header>
        <NavBar />
      </header>
      <body>
        <div className="container">
          <form
            className="row mt-3 g-3 needs-validation"
            onSubmit={showDatabase}
            noValidate
          >
            <div className="col-4">
              <label for="inputFile" className="form-label">
                Registro Civil
              </label>
              <input
                type="file"
                className="form-control"
                onChange={(e) => convertToBase64(e)}
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Enviar
            </button>
          </form>
        </div>
      </body>
    </>
  );
}
