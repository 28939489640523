import { Chart as ChartJs, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJs.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

export default function SchoolsBar ({futbol,baseball,basketBall,danza}){

    const data = {
        labels: ["Futbol","BaseBall","BasketBall","Danza"],
        datasets: [
            {
                data: [futbol,baseball,basketBall,danza],
                backgroundColor: '#26bde2',
                borderColor: '#26bde2',
                borderWidth: 1
            }
        ]
    }

    const options = {
        indexAxis: 'x',
        plugins: {
            display: false
        }
    }
    return(
        <div className="">
            <Bar data={data} options={options} />
        </div>
    )
}