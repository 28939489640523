import axios from "axios";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import NavBar from "../../../Layouts/NavBar";
import FilterListSchool from "./FilterListsSchool";
import { useAuth } from "../../../Services/AuthContext";

export default function () {
  const [school, setSchool] = useState([]);
  const [scholarship, setScholarship] = useState([]);

  const auth = useAuth()
  const showData = async(e) => {
    e.preventDefault()

    const response = await axios.post(`/api/school/addByScholarship`,{
      school: school,scholarship: scholarship
    },{
      headers: {
        authorization: `Bearer ${auth.Ldata}`
      }, withCredentials: true
    })
    console.log(response)
  };
  return (
    <>
      <header>
        <NavBar />
        <FilterListSchool />
      </header>
      <div>
        <form className="row g-3" onSubmit={showData}>
          <div className="col-auto">
            <select
              className="form-select"
              aria-label="Operator"
              onChange={(e) => setScholarship(e.target.value)}
            >
              <option selected>Seleccione escolaridad</option>
              <option value="KINDER">Kinder</option>
              <option value="PREESCOLAR">Preescolar</option>
              <option value="PRIMARIA">Primaria</option>
              <option value="SECUNDARIA">Secundaria</option>
              <option value="PROFESIONAL">Profesional</option>
              <option value="OTRO">Otro</option>
            </select>
          </div>
          <div className="col-auto">
            <select
              className="form-select"
              aria-label="Operator"
              onChange={(e) => setSchool(e.target.value)}
            >
              <option selected>Seleccione el Escuela</option>
              <option value="1">Natacion</option>
              <option value="2">Futbol</option>
              <option value="3">Baseball</option>
              <option value="4">Atletismo</option>
            </select>
          </div>
          <div className="col-auto">
            <button type="submit" className="btn btn-primary mb-3">
              Search Data
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
