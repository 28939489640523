import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../Services/AuthContext";
import NavBar from "../NavBar";
import { toast } from "react-toastify";

export default function UpdateSons() {
  const [firstname, setFirstname] = useState("");
  const [secondname, setSecondname] = useState("");
  const [first_lastname, setFirst_lastname] = useState("");
  const [second_lastname, setSecond_lastname] = useState("");
  const [school_year, setSchool_year] = useState(0);
  const [scholarship, setScholarship] = useState("");
  const [culminated, setCulminated] = useState("");
  const [gender, setGender] = useState("");
  const [date_birth, setDate_birth] = useState("");

  const [sfirstname, ssetFirstname] = useState("");
  const [ssecondname, ssetSecondname] = useState("");
  const [sfirst_lastname, ssetFirst_lastname] = useState("");
  const [ssecond_lastname, ssetSecond_lastname] = useState("");
  const [sschool_year, ssetSchool_year] = useState(0);
  const [sscholarship, ssetScholarship] = useState("");
  const [sculminated, ssetCulminated] = useState("");
  const [sgender, ssetGender] = useState("");
  const [sdate_birth, ssetDate_birth] = useState("");

  const { id } = useParams();
  const auth = useAuth();

  const showDatabase = async (e) => {
    e.preventDefault();

    console.log(firstname, sfirstname);
    const response = await toast.promise(
      axios.put(
        `/api/sons/setSonsData/${id}`,
        {
          firstname: sfirstname ? sfirstname : firstname,
          secondname: ssecondname ? ssecondname : secondname,
          first_lastname: sfirst_lastname ? sfirst_lastname : first_lastname,
          second_lastname: ssecond_lastname
            ? ssecond_lastname
            : second_lastname,
          scholarship: sscholarship ? sscholarship : scholarship,
          school_year: sschool_year ? sschool_year : school_year,
          culminated: sculminated ? sculminated : culminated,
          gender: sgender ? sgender : gender,
          date_birth: sdate_birth ? sdate_birth : date_birth,
        },
        {
          headers: {
            authorization: `Bearer ${auth.Ldata}`,
          },
          withCredentials: true,
        }
      ),
      {
        pending: "Editando Informacion",
        success: "Informacion editada",
        error: "Error al editar",
      }
    );
    console.log(response);
  };

  const getSons = async () => {
    await axios
      .get(`/api/sons/getsonsPUT/${id}`, {
        headers: {
          authorization: `Bearer ${auth.Ldata}`,
        },
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
        setFirstname(response.data[0].firstname);
        setSecondname(response.data[0].secondname);
        setFirst_lastname(response.data[0].first_lastname);
        setSecond_lastname(response.data[0].second_lastname);
        setSchool_year(response.data[0].school_year);
        setScholarship(response.data[0].scholarship);
        setCulminated(response.data[0].culminated);
        setGender(response.data[0].gender);
        setDate_birth(response.data[0].date_birth);
      });
  };

  useEffect(() => {
    showDatabase();
    getSons();
  }, []);
  return (
    <>
      <header>
        <NavBar />
      </header>
      <body>
        <div className="container">
          <form
            className="row mt-3 g-3 needs-validation"
            onSubmit={showDatabase}
            noValidate
          >
            <div className="col-6">
              <label for="nameId" className="form-label">
                Primer Nombre
              </label>
              <input
                type="text"
                className="form-control"
                id="nameId"
                onChange={(e) => ssetFirstname(e.target.value)}
                required
              />
              <div className="valid-feedback">ok</div>
              <div className="invalid-feedback">Obligatorio</div>
            </div>
            <div className="col-6">
              <label for="name2Id" className="form-label">
                Segundo Nombre
              </label>
              <input
                type="text"
                className="form-control"
                id="name2Id"
                onChange={(e) => ssetSecondname(e.target.value)}
                required
              />
              <div className="valid-feedback">ok</div>
              <div className="invalid-feedback">Obligatorio</div>
            </div>
            <div className="col-6">
              <label for="FLastnameId" className="form-label">
                Primer Apellido
              </label>
              <input
                type="text"
                className="form-control"
                id="FLastnameId"
                onChange={(e) => ssetFirst_lastname(e.target.value)}
                required
              />
              <div className="valid-feedback">ok</div>
              <div className="invalid-feedback">Obligatorio</div>
            </div>
            <div className="col-6">
              <label for="SLastnameId" className="form-label">
                Segundo Apellido
              </label>
              <input
                type="text"
                className="form-control"
                id="SFLastnameId"
                onChange={(e) => ssetSecond_lastname(e.target.value)}
                required
              />
              <div className="valid-feedback">ok</div>
              <div className="invalid-feedback">Obligatorio</div>
            </div>
            <div className="col-4">
              <label for="Birth" className="form-label">
                Fecha de Nacimiento
              </label>
              <input
                type="date"
                className="form-control"
                id="Birth"
                onChange={(e) => ssetDate_birth(e.target.value)}
              />
            </div>
            <div className="col-4">
              <label for="inputScholarship" className="form-label">
                Escolaridad
              </label>
              <select
                className="form-select"
                id="inputScholarship"
                onChange={(e) => ssetScholarship(e.target.value)}
                required
              >
                <option selected>Seleccione Nivel de Escolaridad</option>
                <option value="Kinder">Kinder</option>
                <option value="Preescolar">Preescolar</option>
                <option value="Primaria">Primaria</option>
                <option value="Secundaria">Secundaria</option>
                <option value="Tecnica">Tecnica</option>
                <option value="Profesional">Profesional</option>
                <option value="Otro">Otro</option>
              </select>
            </div>
            <div className="col-4">
              <label for="inputCulminated" className="form-label">
                Estado de Escolaridad
              </label>
              <select
                className="form-select"
                id="inputCulminated"
                required
                onChange={(e) => ssetCulminated(e.target.value)}
              >
                <option selected>Seleccione Estado</option>
                <option value="SI">Culminado</option>
                <option value="NO">No Culminado</option>
                <option value="Proceso">En proceso</option>
              </select>
            </div>
            <div className="col-4">
              <label for="ScholarYear" className="form-label">
                Año Escolar
              </label>
              <input
                type="number"
                className="form-control"
                id="ScholarYear"
                onChange={(e) => ssetSchool_year(e.target.value)}
              />
            </div>
            <div className="col-4">
              <label for="validationDefault04" className="form-label">
                Genero
              </label>
              <select
                className="form-select"
                id="validationDefault04"
                onChange={(e) => ssetGender(e.target.value)}
                required
              >
                <option selected>Seleccione El genero</option>
                <option value="M">Masculino</option>
                <option value="F">Femenino</option>
              </select>
            </div>
            <button type="submit" className="btn btn-primary">
              Enviar
            </button>
          </form>
        </div>
      </body>
    </>
  );
}
