import React, { useState, useEffect } from "react";
import NavBar from "../NavBar";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../Services/AuthContext";

export default function RegisterFile() {
  let { id } = useParams();
  const auth = useAuth();

  const [son, setSon] = useState("");
  const [type, setType] = useState("")

  const showDatabase = async () => {
    try {
      await axios.get(`/api/sons/register/${id}`, {
        headers: { Authorization: `Bearer ${auth.Ldata}` },
        withCredentials: true,
      }).then((response)=>{
        setSon(response.data.data)
        setType(response.data.type)
      })
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    showDatabase();
  });
  return (
    <>
      <header>
        <NavBar />
      </header>
      <div>
        {
          <img
            src={`data:${type};base64,${son}`}
            style={{ height: "600px", width: "600px" }}
          />
        }
      </div>
    </>
  );
}
