import axios from "axios";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import NavBar from "../NavBar";
import { useAuth } from "../../Services/AuthContext";

export default function FishingCertificate() {
  const { id } = useParams();
  const auth = useAuth()
  const [employee, setEmployee] = useState("");
  const [type, setType] = useState("")

  const showDatabase = async () => {
    const response = await axios.get(`/api/employee/idfishingCertificate/${id}`,{headers: {
      authorization: `Bearer ${auth.Ldata}`
    }, withCredentials: true}).then((response) =>{
      setEmployee(response.data.data)
      setType(response.data.type)
    })
  };

  useEffect(() => {
    showDatabase();
  });
  return (
    <>
    <header>
      <NavBar />
    </header>
    <div>{<img src={`data:${type};base64,${employee}`} style={{height: '600px', width: '600px'}} />}</div>
  </>
  );
}
