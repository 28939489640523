import React, { useState } from "react";
import NavBar from "../../../Layouts/NavBar";
import { Link } from "react-router-dom";
import "../../../../Stylesheet/Filters.css";

export default function ListFiltersComitte() {
  return (
    <div
      className="btn-group group-filter"
      role="group"
      aria-label="Button-group"
    >
      <div className="btn-group" role="group">
        <button
          type="button"
          className="btn btn-outline-warning dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Asignacion A Comite
        </button>
        <ul className="dropdown-menu">
          <li>
            <Link className="dropdown-item" to={"/Comite/Conyuge"}>
              Asignacion Por Conyugue
            </Link>
            <Link className="dropdown-item" to={"/Comite/Voluntario"}>
              Asignacion Por Voluntario
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
