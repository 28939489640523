import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import NavBar from "../../../Layouts/NavBar";
import Filter from "./Filters";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuth } from "../../../Services/AuthContext";

export default function SonById() {

  const [Sons, setSons] = useState([]);
  const [id,setId] = useState([])
  const auth = useAuth()

  const showData = async (e) => {
    e.preventDefault();

    const response = await toast.promise(
      axios.post(`/api/sons/search`,{
        idson: id
      },{
        headers: {
          authorization: `Bearer ${auth.Ldata}`
        },withCredentials: true
      }
      ),{
        pending: 'Buscando...',
        success: 'Busqueda Exitosa',
        error: 'Error...'
      }
    )
    console.log(response)
    setSons(response.data)
  };

  useEffect(() => {
    showData();
  }, []);

  return (
    <div className="body">
      <header>
        <NavBar />
        <Filter />
      </header>

      <div className="container-fluid">
        <form className="row g-3" onSubmit={showData}>
          <div className="col-auto input-field">
            <label for="inputId" className="visually-hidden">
              Identificacion
            </label>
            <input
              type="text"
              className="form-control"
              id="inputId"
              placeholder="ID"
              onChange={(e) => setId(e.target.value)}
            />
          </div>
          <div className="col-auto">
            <button type="submit" className="btn btn-primary mb-3">
              Search Data
            </button>
          </div>
        </form>
      </div>

      <table className="table table-striped table-hover mt-5 shadow-lg">
        <thead>
          <tr className="bg-grupo text-black ">
            <th>id</th>
            <th>Primer Nombre</th>
            <th>Segundo Nombre</th>
            <th>Primer Apellido</th>
            <th>Segundo Apellido</th>
            <th>Edad</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(Sons).map((son) => (
            <tr key={son.id_son_dougther}>
              <td>{son.id_son_dougther}</td>
              <td>{son.firstname}</td>
              <td>{son.secondname}</td>
              <td>{son.first_lastname}</td>
              <td>{son.second_lastname}</td>
              <td>{son.age}</td>
              <td>
                <Link
                  to={`/Listar/edad/${son.id_son_dougther}`}
                  className="btn btn-outline-primary text-center"
                >
                  Mas Informacion
                </Link>
              </td>
              <td>
                <a href="#!" className="btn btn-outline-primary text-center">
                  Editar
                </a>
              </td>
              <td>
                <a href="#!" className="btn btn-outline-primary text-center">
                  Eliminar
                </a>
              </td>
              <td>
                <Link
                  to={`/Listar/Registro/${son.id_son_dougther}`}
                  className="btn btn-outline-primary text-center"
                >
                  Registro
                </Link>
              </td>
              <td>
                <a href="#!" className="btn btn-outline-primary text-center">
                  Estudio
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
