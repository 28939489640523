import React,{useState,useEffect} from "react"
import Navbar from "../../../Layouts/NavBar"
import FilterMembers from "./FilterMembers"
export default function Member(){

    return(
        <>
        <header>
            <Navbar />
            <FilterMembers />
        </header>
        </>
    )
}